<template>
  <div class="calendar-table">
    <el-table
      :highlight-current-row="false"
      :data="tableData"
      :row-class-name="getRowClass"
      style="width: 100%"
      row-key="type"
      default-expand-all
      @cell-click="handleCellClick"
      @cell-mouse-enter="handleCellMouseEnter"
      @cell-mouse-leave="handleCellMouseLeave"
    >
      <!-- main header -->
      <el-table-column prop="type" label="Inova tech" width="150">
      </el-table-column>

      <el-table-column
        v-for="(month, index) in months"
        :key="index"
        :label="month.name"
      >
        <el-table-column
          v-for="(day, dayIndex) in month.days"
          :key="dayIndex"
          :prop="`${month.year}.${month.month}.${day}`"
          :label="day"
          width="37"
        >
        </el-table-column>
      </el-table-column>

      <!-- <el-table-column label="Januar">
      <el-table-column prop="2025-01-24" label="24" width="60">
      </el-table-column>
      <el-table-column prop="2025-01-25" label="25" width="60">
      </el-table-column>
      <el-table-column prop="2025-01-26" label="26" width="60">
      </el-table-column>
      <el-table-column prop="2025-01-27" label="27" width="60">
      </el-table-column>
    </el-table-column>
    <el-table-column label="Februar">
      <el-table-column prop="2025-02-24" label="24" width="60">
      </el-table-column>
      <el-table-column prop="2025-02-25" label="25" width="60">
      </el-table-column>
      <el-table-column prop="2025-02-26" label="26" width="60">
      </el-table-column>
      <el-table-column prop="2025-02-27" label="27" width="60">
      </el-table-column>
    </el-table-column> -->

      <!-- avail header -->
    </el-table>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";
export default {
  components: {
    "el-table": Table,
    "el-table-column": TableColumn,
  },
  mounted() {
    this.months = this.getMonths(
      new Date().getFullYear(),
      new Date().getMonth(),
      12
    );
  },
  data() {
    return {
      months: [],
      isDragging: false,
      selectedCells: [],

      tableData: [
        // avails
        {
          type: "Dostupnost",
          jan21: "3",
          jan22: "4",
          jan23: "3",
          jan24: "4",
        },
        // price
        {
          type: "Cena",
          21: "",
          22: "",
          23: "",
          24: "",
        },

        // ... other data
        {
          type: "2 RSD",
          21: "",
          22: "",
          23: "",
          24: "",
          children: [
            {
              type: "Room 1",
              21: "",
              22: "",
              23: "",
              24: "",
            },
          ],
        },
        {
          type: "3 RSD",
          21: "",
          22: "",
          23: "",
          24: "",
        },
      ],
    };
  },

  methods: {
    getDaysInMonth(year, month) {
      return new Date(year, month + 1, 0).getDate();
    },

    getMonthDays(year, month) {
      const days = [];
      const lastDay = this.getDaysInMonth(year, month);

      for (let day = 1; day <= lastDay; day++) {
        days.push(day);
      }

      return days;
    },

    getMonths(currentYear, currentMonth, count) {
      const months = [];

      for (let i = 0; i < count; i++) {
        const year = currentYear + i;
        const month = currentMonth + i;

        if (month > 11) {
          year++;
          month = 0;
        }

        const monthName = new Date(year, month).toLocaleString("default", {
          month: "long",
        });

        const days = this.getMonthDays(year, month);

        months.push({
          name: monthName,
          year,
          month,
          days,
        });
      }

      return months;
    },

    handleCellMouseEnter(row, column, cell, event) {
      // console.log("Enter: ", cell);
    },
    handleCellMouseLeave(row, column, cell, event) {
      // console.log("Leave: ", cell);
    },
    handleCellClick(row, column, cell, event) {
      console.log("row: ", row);
      console.log("column: ", column);
      console.log("cell: ", cell);

      // alert(
      //   `Kliknuli ste na ćeliju u redu: ${row.type}, kolona: ${column.label}`
      // );
    },
    getRowClass({ row }) {
      if (row.type === "Dostupnost" || row.type === "Cena") {
        return "type-dostupnost type-cena";
      }
      return "";
    },
  },
};
</script>

<style>
.calendar-table .table,
.el-table table {
  margin-bottom: 0px !important;
}

.calendar-table .el-table .el-table__row.type-dostupnost,
.el-table .el-table__row.type-cena {
  background-color: #cc18b0;
  text-transform: uppercase;
  font-weight: 900;
}

.calendar-table .el-table__cell:hover {
  background-color: #4f0944 !important;
  transition: background-color 0.3s ease;
  cursor: pointer;
}
.calendar-table .el-table__row:hover {
  transition: background-color 0.3s ease;
  background-color: #800b6d !important;
}
.calendar-table .el-table,
.el-table__expanded-cell {
  background-color: transparent !important;
}
/* .el-table .el-table__row.type-dostupnost .cell,
.el-table .el-table__row.type-cena .cell {
  color: #1e1e2f !important;
} */
</style>

<template>
  <div>
    <!-- card search -->
    <div class="mt-3 search-input d-flex flex-wrap">
      <base-input
        v-model="search"
        class="mt-2 mr-2"
        type="search"
        placeholder="Pretraga"
      ></base-input>
      <base-button type="primary" @click="handleSearch">Pretraži</base-button>
    </div>

    <el-table
      :data="cards"
      v-loading="cardsLoading"
      :page-size="pageSize"
      :current-page="currentPage"
      @current-change="handleCurrentChange"
    >
      <el-table-column min-width="150" prop="name" label="Ime">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>

      <el-table-column
        min-width="150"
        header-align="right"
        align="right"
        label="Actions"
      >
        <div slot-scope="scope" class="text-right table-actions">
          <el-tooltip
            content="Izmeni"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              :type="'success'"
              icon
              size="sm"
              class="btn-link"
              @click="handleClick(scope.row)"
            >
              <i class="tim-icons icon-pencil"></i>
            </base-button>
          </el-tooltip>
          <el-tooltip
            content="Obriši"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              :type="'danger'"
              icon
              size="sm"
              class="btn-link"
              @click="handleDelete(scope.row)"
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </el-tooltip>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination
      popper-class="custom-pagination bg-dark"
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { TabPane, Tabs, Collapse, CollapseItem } from "src/components";
import {
  TimeSelect,
  Table,
  TableColumn,
  Select,
  Option,
  Pagination,
} from "element-ui";
import { BaseCheckbox, BaseProgress, RouteBreadCrumb } from "src/components";
import axios from "@/util/axiosInstance";
import swal from "sweetalert2";
import { BaseNav, Modal } from "@/components";

export default {
  name: "card-table",
  props: {
    cardSubmited: {
      card: Boolean,
      default: false,
      sync: true,
    },
  },

  watch: {
    cardSubmited(newValue, oldValue) {
      if (newValue) {
        this.getCards();
        this.$emit("submitedfinish");
      }
    },
  },

  components: {
    [Table.name]: Table,
    [TimeSelect.name]: TimeSelect,
    [TableColumn.name]: TableColumn,
    [Option.name]: Option,
    [Select.name]: Select,
    [Pagination.name]: Pagination,
    TabPane,
    Tabs,
    Collapse,
    CollapseItem,
    Modal,
  },
  mounted() {
    this.getCards();
  },
  data() {
    return {
      search: "",
      cardsLoading: false,
      activeName: "first",
      typeDialog: false,
      cards: [],
      url: "https://admin.hotelsync.org/pms/api/v1",
      cardType: {},
      pageSize: 10,
      currentPage: 1,
      total: 0,
    };
  },
  methods: {
    handleSearch() {
      this.getCards();
    },
    handleClick(row) {
      this.$emit("edit", row);
    },
    handleDelete(row) {
      swal
        .fire({
          title: "Da li ste sigurni?",
          text: "Podatak se ne može vratiti!",
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          confirmButtonText: "Da, obrisi!",
        })
        .then(async (result) => {
          if (result.value) {
            let result = await axios.delete("/cards/" + row.id);
            if (result) {
              await this.getCards();
            }
          }
        });
    },
    async getCards() {
      this.zonesLoading = true;
      let result = await axios.get(
        "/cards?skip=" +
          this.currentPage +
          "&limit=" +
          this.pageSize +
          "" +
          "&search=" +
          this.search
      );

      if (result.data.results) {
        this.cardsLoading = false;
        this.cards = result.data.results;
        this.total = result.data.total;
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getCards();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getCards();
    },
  },
};
</script>

<template>
    <div>
        <div v-if="loading" class="loader-container">
            <div class="loader"></div> <!-- Stilizovani loader -->
        </div>
        <div class="row">
            <div class="col-md-24">
                <card>
                    <template slot="header">
                        <h5 class="card-category"></h5>
                        <h3 class="card-title">Pretplatnici ({{ whitelist.length }})</h3>
                        <base-button class="mt-3" native-type="submit" style="margin-left: 20px;" type="primary"
                            @click="whitelistCustomer = {}; editWhitelist = true">Novi pretplatnik</base-button>
                    </template>
                    <div class="row">
                        <div class="col-md-3">
                            <card>
                                <h4 slot="header" class="card-title">Početak</h4>
                                <base-input>
                                    <el-date-picker type="datetime" placeholder="Početak" value-format="timestamp"
                                        v-model="filter.startStamp">
                                    </el-date-picker>
                                </base-input>
                            </card>
                        </div>
                        <div class="col-md-3">
                            <card>
                                <h4 slot="header" class="card-title">Kraj</h4>
                                <base-input>
                                    <el-date-picker type="datetime" placeholder="Kraj" value-format="timestamp"
                                        v-model="filter.endStamp">
                                    </el-date-picker>
                                </base-input>
                            </card>
                        </div>
                        <div class="col-md-3">
                            <card>
                                <h4 slot="header" class="card-title">Tablice</h4>
                                <base-input type="text" v-model="filter.plates" placeholder="Tablice">
                                </base-input>
                            </card>
                        </div>
                        <div class="col-md-3">
                            <card>
                                <h4 slot="header" class="card-title">Ime</h4>
                                <base-input type="text" v-model="filter.customerName" placeholder="Ime">
                                </base-input>
                            </card>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-3">
                            <card>
                                <h4 slot="header" class="card-title">Matični broj</h4>
                                <base-input type="text" v-model="filter.personalNumber" placeholder="Matični broj">
                                </base-input>
                            </card>
                        </div>
                        <div class="col-md-3">
                            <card>
                                <h4 slot="header" class="card-title">Broj telefona</h4>
                                <base-input type="text" v-model="filter.phone" placeholder="Broj telefona">
                                </base-input>
                            </card>
                        </div>
                        <div class="col-md-3">
                            <card>
                                <h4 slot="header" class="card-title">Status</h4>
                                <base-input>
                                    <el-select v-model="filter.status" class="select-primary" clearable name="status">
                                        <el-option v-for="s in statuses" class="select-primary"
                                            :label="s.name" :value="s.status" :key="s.status"></el-option>
                                    </el-select>
                                </base-input>
                            </card>
                        </div>
                        <div class="col-md-3">
                            <card>
                                <h4 slot="header" class="card-title"> </h4>
                                <base-button class="mt-3" native-type="submit" type="primary"
                                    @click="getWhitelist()">Pretraži</base-button>
                            </card>
                        </div>
                    </div>
                    <el-table :data="whitelist" v-loading="ticketsLoading">
                        <el-table-column min-width="100" prop="customerName" label="Ime">
                            <template slot-scope="scope">
                                <span>{{ scope.row.customerName }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="90" prop="plates" label="Tablice">
                            <template slot-scope="scope">
                                <span>{{ scope.row.plates }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="110" prop="dateFrom" label="Od">
                            <template slot-scope="scope">
                                <span>{{ (new Date(scope.row.dateFrom)).toLocaleString("de-De") }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="110" prop="dateTo" label="Do">
                            <template slot-scope="scope">
                                <span>{{ (new Date(scope.row.dateTo)).toLocaleString("de-De") }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="110" prop="stamp" label="Kreirano">
                            <template slot-scope="scope">
                                <span>{{ (new Date(scope.row.stamp)).toLocaleString("de-De") }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="100" prop="timeStamp" label="Datum izmene">
                            <template slot-scope="scope">
                                <span>{{ (new Date(scope.row.timeStamp)).toLocaleString("de-De") }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="100" prop="categoryType" label="Tip">
                            <template slot-scope="scope">
                                <span>{{ scope.row.whitelistType?.name }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="110" prop="statusName" label="Status">
                            <template slot-scope="scope">
                                <span v-if="scope.row.status === 0" style="background-color: green;">{{ scope.row.statusName }}</span>
                                <span v-if="scope.row.status === 3 || scope.row.status === 4" style="background-color: orange; color: black">{{ scope.row.statusName }}</span>

                                <span v-if="scope.row.status === 2 || scope.row.status === 1" style="background-color: red;">{{ scope.row.statusName }}</span>

                            </template>
                        </el-table-column>
                        <!--
                        <el-table-column min-width="100" prop="plates" label="Tablice">
                            <template slot-scope="scope">
                                <span>{{ scope.row.plates }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="60" prop="price" label="Cena">
                            <template slot-scope="scope">
                                <span>{{ scope.row.price }} EUR</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="100" prop="paymentStatus" label="Status">
                            <template slot-scope="scope">
                                <span>{{ scope.row.paymentStatus.name }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="100" prop="ticketNumber" label="ID">
                            <template slot-scope="scope">
                                <span>{{ scope.row.ticketNumber }}</span>
                            </template>
                        </el-table-column>
-->
                        <el-table-column min-width="150" header-align="right" align="right" label="Actions">
                            <div slot-scope="scope" class="text-right table-actions">

                                <base-button :type="'success'" class="btn-link"
                                    @click="whitelistCustomer = scope.row;whitelistCustomer.extend = false; editWhitelist = true">
                                    Detalji
                                </base-button>

                                <base-button v-if="scope.row.status !== 3 && scope.row.status !== 4" :type="'warning'" class="btn-link"
                                    @click="whitelistCustomer = scope.row;whitelistCustomer.extend = true; editWhitelist = true">
                                    Produži
                                </base-button>

                                <base-button v-if="scope.row.status === 0" :type="'danger'" class="btn-link"
                                    @click="whitelistCustomer = scope.row; showCancelPrompt()">
                                    Otkaži
                                </base-button>

                            </div>
                        </el-table-column>
                    </el-table>
                </card>
            </div>


        </div>
        <modal :show.sync="editWhitelist" :show-close="true" :scrollToBottom="true" :appendToBody="true"
            modalClasses="custom-modal" modalContentClasses="custom-modal-content" bodyClasses="custom-modal-body">
            <base-button :type="'danger'" icon size="sm" class="btn-link"
                @click="editWhitelist = false; whitelist = {}">
                <i class="tim-icons icon-simple-remove"></i>
            </base-button>


            <card class="stacked-form" title="Pretplatnik">
                <tabs type="primary">
                    <tab-pane label="Podaci">
                        <form @submit.prevent>
                            <div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <base-input required label="Ime" type="text" v-model="whitelistCustomer.customerName"
                                            placeholder="Ime">
                                        </base-input>
                                    </div>
                                    <div class="col-md-6">
                                        <template v-if="whitelistCustomer.id && !whitelistCustomer.extend">
                                            <base-input required label="Tablice">
                                                {{ whitelistCustomer.plates }}
                                             </base-input>     
                                        </template>
                                        <base-input v-else required label="Tablice" type="text" v-model="whitelistCustomer.plates"
                                            placeholder="Tablice">
                                        </base-input>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <base-input label="Država" type="text" v-model="whitelistCustomer.country"
                                            placeholder="Država">
                                        </base-input>
                                    </div>
                                    <div class="col-md-6">
                                        <base-input label="Adresa" type="text" v-model="whitelistCustomer.address"
                                            placeholder="Adresa">
                                        </base-input>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <base-input label="Matični broj" type="text"
                                            v-model="whitelistCustomer.personalNumber" placeholder="Matični broj">
                                        </base-input>
                                    </div>
                                    <div class="col-md-6">
                                        <base-input required label="Broj telefona" type="text" v-model="whitelistCustomer.phone"
                                            placeholder="Broj telefona">
                                        </base-input>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <base-input label="Fiskalni račun" type="text"
                                            v-model="whitelistCustomer.fiscalNumber" placeholder="Fiskalni račun">
                                        </base-input>
                                    </div>
                                    <div class="col-md-6">
                                        <base-input required label="Cena" type="text" v-model="whitelistCustomer.price"
                                            placeholder="Cena">
                                        </base-input>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <base-input required label="Od">
                                            <template v-if="whitelistCustomer.id && !whitelistCustomer.extend">
                                                    {{ (new Date(whitelistCustomer.dateFrom)).toLocaleString("de-DE") }}
                                            </template>
                                        
                                            <el-date-picker v-else type="datetime" placeholder="Početak"
                                                value-format="timestamp" v-model="whitelistCustomer.dateFrom">
                                            </el-date-picker>
                                        </base-input>
                                    </div>
                                    <div class="col-md-6">
                                        <base-input required label="Do">
                                            <template v-if="whitelistCustomer.id && !whitelistCustomer.extend">
                                                    {{ (new Date(whitelistCustomer.dateTo)).toLocaleString("de-DE") }}
                                            </template>
                                            
                                            <el-date-picker v-else type="datetime" placeholder="Kraj"
                                                value-format="timestamp" v-model="whitelistCustomer.dateTo">
                                            </el-date-picker>
                                            
                                        </base-input>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <base-input label="Ugovor" type="text"
                                            v-model="whitelistCustomer.contractNumber" placeholder="Ugovor">
                                        </base-input>
                                    </div>
                                    <div class="col-md-6">
                                        <base-input required label="Ugovor važi do">
                                            <el-date-picker type="datetime" placeholder="Ugovor važi do"
                                                value-format="timestamp" v-model="whitelistCustomer.contractExpireLong">
                                            </el-date-picker>
                                        </base-input>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <base-input label="Komentar" type="text" v-model="whitelistCustomer.comment"
                                            placeholder="Komentar">
                                        </base-input>
                                    </div>
                                    <div class="col-md-6">
                                        <base-input label="E-Mail" type="text" v-model="whitelistCustomer.email"
                                            placeholder="E-Mail">
                                        </base-input>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </tab-pane>

                    <tab-pane label="Lokacije">
                        <el-select v-model="whitelistCustomer.whitelistTypeId" class="select-primary"
                            @change="getWhitelistTypeParkingsOnly" name="whitelistType">
                            <el-option v-for="t in whitelistTypes" class="select-primary" :label="t.name" :value="t.id"
                                :key="t.id"></el-option>
                        </el-select>
                        <el-table :data="whitelistCustomer.parkings" v-loading="ticketsLoading">

                            <el-table-column min-width="80" prop="name" label="Parking">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.name }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column min-width="100" prop="client" label="Zona">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.client?.name }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column min-width="100" prop="checked" label="">
                                <template slot-scope="scope">
                                    <span>
                                        <base-checkbox class="text-left" v-model="scope.row.checked">

                                        </base-checkbox>
                                    </span>
                                </template>
                            </el-table-column>

                            <!-- <el-table-column min-width="100" header-align="right" align="right" label="Actions">
                                <div slot-scope="scope" class="text-right table-actions">

                                    <base-button :type="'success'" class="btn-link"
                                        @click="ticket = scope.row; showTicket = true">
                                        Detalji
                                    </base-button>

                                </div>
                            </el-table-column> -->
                        </el-table>
                    </tab-pane>
                </tabs>
                <base-button class="mt-3" native-type="submit" style="margin-left: 20px;" type="primary"
                    @click="saveWhitelist()">Sačuvaj</base-button>
                <base-button class="mt-3" native-type="submit" style="margin-left: 20px;" type="danger"
                    @click="editWhitelist = false; whitelistCustomer = false">Odustani</base-button>
            </card>

        </modal>

    </div>
</template>
<script>
import { TabPane, Tabs, Collapse, CollapseItem } from 'src/components';
import { Table, TableColumn, TimeSelect, DatePicker, Select, Option } from 'element-ui';
import { BaseCheckbox, BaseProgress, RouteBreadCrumb } from 'src/components';
import axios from 'axios'
import swal from 'sweetalert2';
import { BaseNav, Modal } from '@/components';
import htmlDocx from 'html-docx-js';
import { saveAs } from 'file-saver';

const statuses = [
    { "status": 0, "name": "AKTIVNA"},
    { "status": 1, "name": "ISTEKLA"},
    { "status": 2, "name": "OTKAZANA"},
    { "status": 3, "name": "ISTEKLA I PRODUŽENA"},
    { "status": 4, "name": "OTKAZANA I AKTIVIRANA"}
]

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [DatePicker.name]: DatePicker,
        [TimeSelect.name]: TimeSelect,
        [Option.name]: Option,
        [Select.name]: Select,
        TabPane,
        Tabs,
        Collapse,
        CollapseItem,
        Modal
    },
    mounted() {
        this.getWhitelist()
        this.getWhitelistTypes()
    },
    data() {
        const now = new Date();

        // Postavljanje startStamp na 00:00:00 danas
        const startStamp = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0).getTime();

        // Postavljanje endStamp na 23:59:59 danas
        const endStamp = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59).getTime();

        return {
            statuses,
            url: 'https://hercegnovi.scancar.org:8443/kuca',
            loading: false,
            filter: {
                startStamp: startStamp,
                endStamp: endStamp,
                name: null,
                plates: null,
                personalNumber: null,
                status: null,
                phone: null
            },
            ticketsLoading: false,
            whitelist: [],
            whitelistCustomer: {},
            editWhitelist: false,
            whitelistTypes: []
        };
    },
    methods: {

        sendMessageToAndroid() {
            // Pozivanje funkcije iz Android-a
            Android.callJavaFunction("Pozdrav iz JavaScript-a!");
        },
        saveWhitelist() {
            this.loading = true
            axios.post(this.url + '/whitelist/saveWhitelist', this.whitelistCustomer).then(result => {
                this.loading = false

                if (result.data.result === 'ok') {
                    this.$notify({
                        message:
                            'Sačuvano',
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'success'
                    });
                    this.whitelistCustomer = {}
                    this.editWhitelist = false
                    this.getWhitelist()
                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        getWhitelistTypes() {
            axios.get(this.url + '/whitelist/getWhitelistTypes').then(result => {

                if (result.data.result === 'ok') {
                    this.whitelistTypes = result.data.whitelistTypes
                } else {

                }
            })
        },
        getWhitelistTypeParkingsOnly() {
            this.loading = true
            axios.post(this.url + '/whitelist/getWhitelistTypeParkingsOnly', this.whitelistCustomer).then(result => {
                this.loading = false
                if (result.data.result === 'ok') {
                    this.whitelistCustomer.parkings = [...result.data.parkings];
                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        getWhitelist() {
            this.ticketsLoading = true
            const f = this.filter
            f.whitelistCategoryId = "1"
            // f.startStamp = this.filter.startStamp.getTime()
            // f.endStamp = this.filter.endStamp.getTime()
            axios.post(this.url + '/whitelist/getWhitelist', f).then(result => {
                this.ticketsLoading = false

                if (result.data.result === 'ok') {
                    this.whitelist = result.data.whitelist
                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        cancelCustomer() {
            this.loading = true
            
            axios.post(this.url + '/whitelist/cancelWhitelist', this.whitelistCustomer).then(result => {
                this.loading = false

                if (result.data.result === 'ok') {
                    this.$notify({
                        message:
                            'Otkazano',
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'success'
                    });     
                    this.getWhitelist()           
                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        showCancelPrompt(){
            const swalWithBootstrapButtons4 = swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success btn-fill",
                    cancelButton: "btn btn-danger btn-fill",
                },
                buttonsStyling: false,
            });
            swalWithBootstrapButtons4
                .fire({
                    title: "Da li ste sigurni da želite da otkažete pretplatnika \"" + this.whitelistCustomer.customerName + "\"?",
                    text: ``,
                    confirmButtonText: "Da, otkaži!",
                    cancelButtonText: "NE",
                    showCancelButton: true,
                })
                .then((result) => {
                    if (result.value) {
                        this.cancelCustomer()
                    }
                });
        }

    }
};
</script>
<style lang="scss">
/* Pozadina modala */
.custom-modal {
    background-color: rgba(0, 0, 0, 0.8);
}

.custom-modal-content {
    background-color: #3b3b5c;
    /* Tamna pozadina */
    color: #ffffff;
    /* Svetli tekst */
    border-radius: 8px;
    max-width: 800px;
    width: 800px;
}

/* Stil za telo modala */
.custom-modal-body {
    padding: 20px;
}

/* Stil za tabelu unutar modala */
.custom-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #2c2c3e;
    /* Tamna pozadina tabele */
    color: rgba(255, 255, 255, 0.87);
    /* Svetlo sivi tekst */
}

.custom-table th {
    background-color: #3c3c4f;
    /* Tamna pozadina za zaglavlje */
    color: #ffffff;
    /* Beli tekst */
    padding: 10px;
    text-align: left;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.custom-table td {
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.87);
    /* Svetlo sivi tekst */
}

.custom-table tr:hover {
    background-color: rgba(255, 255, 255, 0.1);
    /* Istaknuta boja za hover */
}


.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Visina loadera */
    background-color: rgba(255, 255, 255, 0.7);
    /* Providna pozadina */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    /* Iznad ostalog sadržaja */
}

.loader {
    border: 8px solid #f3f3f3;
    /* Svetla boja */
    border-top: 8px solid #3498db;
    /* Boja animacije */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
import VueRouter from "vue-router";
import routes from "./routes";
import axios from "@/util/axiosInstance";

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem("access_token");
  const refreshToken = localStorage.getItem("refresh_token");
  // if (token && to.name === "Login") {
  //   next({ name: "Administration" });
  // }
  if (token) {
    try {
      let result = await axios.post("/auth/introspect", {
        refreshToken,
        token,
      });
      if (result.data.active) {
        next();
      } else {
        if (result.data?.access_token) {
          console.log("new access token: ", result?.data);

          localStorage.setItem("access_token", result.data.access_token);
          localStorage.setItem("refresh_token", result.data.refresh_token);
          next();
        } else {
          if (to.name !== "Login") {
            next({ name: "Login" });
          } else {
            next();
          }
        }
      }
    } catch (error) {
      console.error("Token introspection error:", error);
      if (to.name !== "Login") {
        next({ name: "Login" });
      } else {
        next();
      }
    }
  } else {
    if (to.name !== "Login") {
      next({ name: "Login" });
    } else {
      next();
    }
  }
});

export default router;

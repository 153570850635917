<template>
    <div>
        <div v-if="loading" class="loader-container">
            <div class="loader"></div> <!-- Stilizovani loader -->
        </div>
        <div class="row">
            <div class="col-md-24">
                <card>
                    <template slot="header">
                        <h5 class="card-category"></h5>
                        <h3 class="card-title">Kiosk karte ({{ tickets.length }})</h3>
                    </template>
                    <div class="row">
                        <div class="col-md-3">
                            <card>
                                <h4 slot="header" class="card-title">Početak</h4>
                                <base-input>
                                    <el-date-picker type="datetime" placeholder="Početak" value-format="timestamp"
                                        v-model="filter.startStamp">
                                    </el-date-picker>
                                </base-input>
                            </card>
                        </div>
                        <div class="col-md-3">
                            <card>
                                <h4 slot="header" class="card-title">Kraj</h4>
                                <base-input>
                                    <el-date-picker type="datetime" placeholder="Kraj" value-format="timestamp"
                                        v-model="filter.endStamp">
                                    </el-date-picker>
                                </base-input>
                            </card>
                        </div>
                        <div class="col-md-3">
                            <card>
                                <h4 slot="header" class="card-title">Tablice</h4>
                                <base-input type="text" v-model="filter.plates" placeholder="Tablice">
                                </base-input>
                            </card>
                        </div>
                        <div class="col-md-3">
                            <card>
                                <h4 slot="header" class="card-title">Broj karte</h4>
                                <base-input type="text" v-model="filter.ticketNumber" placeholder="Broj karte">
                                </base-input>
                            </card>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-3">
                            <card>
                                <h4 slot="header" class="card-title">Zona</h4>
                                <base-input>
                                    <el-select v-model="filter.clientId" class="select-primary" name="zone">
                                        <el-option v-for="zone in zones" class="select-primary" :label="zone.name"
                                            :value="zone.id" :key="zone.id"></el-option>
                                    </el-select>
                                </base-input>
                            </card>
                        </div>
                        <div class="col-md-3">
                            <card>
                                <h4 slot="header" class="card-title">Kiosk</h4>
                                <base-input>
                                    <el-select v-model="filter.kioskId" class="select-primary" name="zone">
                                        <el-option v-for="zone in kiosks" class="select-primary" :label="zone.name"
                                            :value="zone.id" :key="zone.id"></el-option>
                                    </el-select>
                                </base-input>
                            </card>
                        </div>
                        <div class="col-md-3">
                            <!-- <card>
                                <h4 slot="header" class="card-title">Status plaćanja</h4>
                                <base-input>
                                    <el-select v-model="filter.paymentStatusId" class="select-primary" name="zone">
                                        <el-option v-for="zone in paymentStatuses" class="select-primary"
                                            :label="zone.name" :value="zone.id" :key="zone.id"></el-option>
                                    </el-select>
                                </base-input>
                            </card> -->
                        </div>
                        <div class="col-md-3">
                            <card>
                                <h4 slot="header" class="card-title"> </h4>
                                <base-button class="mt-3" native-type="submit" type="primary"
                                    @click="getTickets()">Pretraži</base-button>
                            </card>
                        </div>
                    </div>
                    <el-table :data="tickets" v-loading="ticketsLoading">
                        <el-table-column min-width="130" prop="stamp" label="Datum">
                            <template slot-scope="scope">
                                <span>{{ (new Date(scope.row.stampLong)).toLocaleString("de-DE") }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="80" prop="client" label="Zona">
                            <template slot-scope="scope">
                                <span>{{ scope.row.client?.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="80" prop="kiosk" label="Kiosk">
                            <template slot-scope="scope">
                                <span>{{ scope.row.kiosk?.name }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="80" prop="plates" label="Tablice">
                            <template slot-scope="scope">
                                <span>{{ scope.row.plates }}</span>
                            </template>
                        </el-table-column>
                        
                    </el-table>
                </card>
            </div>


        </div>
        <modal :show.sync="showTicket" :show-close="true" :scrollToBottom="true" :appendToBody="true"
            modalClasses="custom-modal" modalContentClasses="custom-modal-content" bodyClasses="custom-modal-body">
            <base-button :type="'danger'" icon size="sm" class="btn-link" @click="showTicket = false; ticket = {}">
                <i class="tim-icons icon-simple-remove"></i>
            </base-button>


            <div class="row">
                <div class="col-md-12">
                    <card>
                        ID: {{ ticket.ticketNumber }}&nbsp;&nbsp;&nbsp;
                        {{ ticket.plates }}&nbsp;&nbsp;&nbsp;
                        <template v-if="ticket.checkedPlates">
                            {{ (new Date(ticket.checkedPlates?.stamp)).toLocaleString("de-DE") }} -
                        </template>
                        {{ (new Date(ticket.stamp)).toLocaleString("de-DE") }}&nbsp;&nbsp;&nbsp;
                        {{ ticket.exceded }}
                    </card>
                </div>
                <!-- <div class="col-md-4">
                    <card>
                        <template v-if="ticket.checkedPlates">
                            {{ (new Date(ticket.checkedPlates?.stamp)).toLocaleString("de-DE") }}<br>
                        </template>
                        {{ (new Date(ticket.stamp)).toLocaleString("de-DE") }}
                    </card>
                </div> -->
                <!-- <div class="col-md-4">
                    <card>
                        {{ ticket.parking?.client.name }}
                    </card>
                </div> -->

            </div>

            <div class="row">
                <div class="col-md-12">
                    <card>
                        {{ ticket.parking?.client.name }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {{ ticket.parking?.name }} - {{ ticket.parking?.costCenter
                        }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {{ ticket.countryMark }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {{ ticket.parkingInspectionItem?.inspectionItem?.name }}, {{
            ticket.parkingInspectionItem?.price }}
                        EUR
                    </card>
                </div>
                <!-- <div class="col-md-4">
                    <card>
                        {{ ticket.plates }}
                    </card>
                </div>
                <div class="col-md-4">
                    <card>
                        {{ ticket.countryMark }}
                    </card>
                </div> -->


            </div>
            <div class="row">
                <!-- <div class="col-md-4">
                    <card>
                        {{ ticket.parkingInspectionItem?.inspectionItem?.name }}, {{
            ticket.parkingInspectionItem?.price }}
                        EUR
                    </card>
                </div> -->

                <div class="col-md-12">
                    <!-- {{ ticket.paymentStatus?.name }} -->
                    <card>
                        <base-input>

                            <el-select v-model="ticket.paymentStatus" class="select-primary" name="zone" value-key="id"
                                style="width: 200px;">
                                <el-option v-for="zone in paymentStatuses" class="select-primary" :label="zone.name"
                                    :value="zone" :key="zone.id"></el-option>
                            </el-select>
                            <base-button class="mt-3" native-type="submit" style="margin-left: 20px;" type="primary"
                                @click="saveTicketPrompt()">Sačuvaj</base-button>
                            <base-button class="mt-3" native-type="submit" style="margin-left: 20px;" type="danger"
                                @click="saveKiosk()">Opomena</base-button>

                            <base-button class="mt-3" native-type="submit" style="margin-left: 20px;" type="primary"
                                @click="getTIcketHistory()">Istorija</base-button>
                        </base-input>
                    </card>

                </div>

                <!-- <div class="col-md-4">
                            <card>
                            <base-button class="mt-3" native-type="submit" type="primary" @click="saveKiosk()">Sačuvaj</base-button>
                        </card>
                        </div> -->
            </div>
            <tabs type="primary">
                <tab-pane label="Doplatna karta">

                    <template v-for="img in ticket.images">
                        <img class="card-img" style="width: 150px;"
                            @click="image = 'https://hercegnovi.scancar.org:8443' + img; showImage = true"
                            :src="'https://hercegnovi.scancar.org:8443' + img" alt="Image" />&nbsp;
                    </template>
                </tab-pane>

                <tab-pane v-if="ticket.checkedPlates" label="Prvo skeniranje">
                    <template v-for="img in ticket.checkedPlates?.images">
                        <img class="card-img" style="width: 150px;"
                            @click="image = 'https://hercegnovi.scancar.org:8443' + img; showImage = true"
                            :src="'https://hercegnovi.scancar.org:8443' + img" alt="Image" />&nbsp;
                    </template>
                </tab-pane>



            </tabs>

        </modal>
        <modal :show.sync="showImage" :show-close="true" :scrollToBottom="true" :appendToBody="true"
            modalClasses="custom-modal" modalContentClasses="custom-modal-content" bodyClasses="custom-modal-body">
            <base-button :type="'danger'" icon size="sm" class="btn-link" @click="showImage = false; image = null">
                <i class="tim-icons icon-simple-remove"></i>
            </base-button>
            <card class="stacked-form" title="Stacked Form">
                <h4 slot="header" class="card-title">{{ ticket.plates }}</h4>
                <img class="card-img" :src="image" alt="Image" />&nbsp;

            </card>


        </modal>

        <modal :show.sync="showTicketHistory" :show-close="true" :scrollToBottom="true" :appendToBody="true"
            modalClasses="custom-modal" modalContentClasses="custom-modal-content" bodyClasses="custom-modal-body">
            <base-button :type="'danger'" icon size="sm" class="btn-link" @click="showTicketHistory = false;">
                <i class="tim-icons icon-simple-remove"></i>
            </base-button>
            <el-table :data="thicketHistory">
                <el-table-column min-width="150" prop="number" label="Dogadjaj">
                    <template slot-scope="scope">
                        <span>
                            {{ scope.row.log }}
                        </span>
                    </template>
                </el-table-column>

                <el-table-column min-width="150" prop="active" label="Komentar">
                    <template slot-scope="scope">
                        <span>
                            {{ scope.row.comment }}
                        </span>
                    </template>
                </el-table-column>

                <el-table-column min-width="100" prop="active" label="Korisnik">
                    <template slot-scope="scope">
                        <span>
                            {{ scope.row.userName }}
                        </span>
                    </template>
                </el-table-column>

                <el-table-column min-width="100" prop="active" label="Datum">
                    <template slot-scope="scope">
                        <span>
                            {{ (new Date(scope.row.stamp)).toLocaleString("de-DE") }}
                        </span>
                    </template>
                </el-table-column>


            </el-table>
            <base-button :type="'danger'" size="sm" class="btn-link" @click="showTicketHistory = false;">
                Zatvori
            </base-button>
        </modal>
    </div>
</template>
<script>
import { TabPane, Tabs, Collapse, CollapseItem } from 'src/components';
import { Table, TableColumn, TimeSelect, DatePicker, Select, Option } from 'element-ui';
import { BaseCheckbox, BaseProgress, RouteBreadCrumb } from 'src/components';
import axios from 'axios'
import swal from 'sweetalert2';
import { BaseNav, Modal } from '@/components';
import htmlDocx from 'html-docx-js';
import { saveAs } from 'file-saver';


export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [DatePicker.name]: DatePicker,
        [TimeSelect.name]: TimeSelect,
        [Option.name]: Option,
        [Select.name]: Select,
        TabPane,
        Tabs,
        Collapse,
        CollapseItem,
        Modal
    },
    mounted() {
        this.getTickets()
        this.getZones()
        this.getKiosks()
        // this.getPaymentStatuses()
    },
    data() {
        const now = new Date();

        // Postavljanje startStamp na 00:00:00 danas
        const startStamp = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0).getTime();

        // Postavljanje endStamp na 23:59:59 danas
        const endStamp = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59).getTime();

        return {
            url: 'https://hercegnovi.scancar.org:8443/kuca',
            ticketsLoading: false,
            loading: false,
            tickets: [],
            filter: {
                startStamp: startStamp,
                endStamp: endStamp,
                ticketNumber: null,
                plates: null,
                countryMark: null,
                kioskId: null,
                paymentStatusId: null,
                clientId: null
            },
            employees: [],
            paymentStatuses: [],
            zones: [],
            ticketsLoading: false,
            ticket: {},
            showTicket: false,
            image: null,
            showImage: false,
            thicketHistory: [],
            showTicketHistory: false,
            kiosks: []
        };
    },
    methods: {
        saveTicket() {
            this.loading = true
            axios.post(this.url + '/ticket/cancelTicket', this.ticket).then(result => {
                this.loading = false

                if (result.data.result === 'ok') {
                    this.$notify({
                        message:
                            'Sačuvano',
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'success'
                    });

                    this.getTickets()
                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        getTIcketHistory() {
            this.loading = true
            axios.get(this.url + '/ticket/getTicketHistory?ticketId=' + this.ticket.id).then(result => {
                this.loading = false

                if (result.data.result === 'ok') {
                    this.thicketHistory = result.data.history
                    this.showTicketHistory = true
                } else {

                }
            })
        },
        getZones() {
            // this.zonesLoading = true
            axios.get(this.url + '/client/getClients').then(result => {
                // this.zonesLoading = false

                if (result.data.result === 'ok') {
                    this.zones = result.data.clients
                } else {

                }
            })
        },
        getPaymentStatuses() {
            // this.zonesLoading = true
            axios.get(this.url + '/settings/getPaymentStatuses').then(result => {
                // this.zonesLoading = false

                if (result.data.result === 'ok') {
                    this.paymentStatuses = result.data.paymentStatuses
                } else {

                }
            })
        },
        getKiosks() {
            // this.employeesLoading = true
            axios.get(this.url + '/kiosk/getKiosks').then(result => {
                // this.employeesLoading = false

                if (result.data.result === 'ok') {
                    this.kiosks = result.data.kiosks
                } else {

                }
            })
        },
        getTickets() {
            this.ticketsLoading = true
            const f = this.filter
            // f.startStamp = this.filter.startStamp.getTime()
            // f.endStamp = this.filter.endStamp.getTime()
            axios.post(this.url + '/kiosk/getTickets', f).then(result => {
                this.ticketsLoading = false

                if (result.data.result === 'ok') {
                    this.tickets = result.data.tickets
                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        navigateToPage() {
            this.$router.push({ name: 'Administration' });
        },
        deletePrompt() {
            const swalWithBootstrapButtons4 = swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success btn-fill",
                    cancelButton: "btn btn-danger btn-fill",
                },
                buttonsStyling: false,
            });
            swalWithBootstrapButtons4
                .fire({
                    title: "Da li ste sigurni da želite da parking mesto \"" + this.parkingSpace.number + "\"?",
                    text: ``,
                    confirmButtonText: "Da, obriši!",
                    cancelButtonText: "NE",
                    showCancelButton: true,
                })
                .then((result) => {
                    if (result.value) {
                        this.deleteParkingSpace()
                        // const swalWithBootstrapButtons5 = swal.mixin({
                        //     customClass: {
                        //     confirmButton: "btn btn-success btn-fill",
                        //     },
                        //     buttonsStyling: false,
                        // });
                        // swalWithBootstrapButtons5.fire({
                        //     title: "Deleted!",
                        //     text: "Your file has been deleted.",
                        // });
                    }
                });
        },
        saveTicketPrompt() {
            const swalWithBootstrapButtons4 = swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success btn-fill",
                    cancelButton: "btn btn-danger btn-fill",
                },
                buttonsStyling: false,
            });

            swalWithBootstrapButtons4
                .fire({
                    title: "Molimo vas unesite komentar za ovu promenu.",
                    html: `<textarea id="comment" class="swal2-textarea" placeholder="Komentar" style="width: 100%; height: 100px;"></textarea>`,
                    confirmButtonText: "Sačuvaj",
                    cancelButtonText: "Odustani",
                    showCancelButton: true,
                    preConfirm: () => {
                        const comment = document.getElementById("comment").value;
                        // if (!comment.trim()) {
                        //     Swal.showValidationMessage("Molimo unesite komentar pre nego što nastavite!");
                        // }
                        return comment;
                    },
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        const comment = result.value; // Dohvaćen komentar
                        console.log("Komentar:", comment); // Možete proslediti komentar funkciji
                        // this.deleteParkingSpace(comment);
                        this.ticket.comment = comment;
                        this.saveTicket()
                    }
                });
        }

    }
};
</script>
<style lang="scss">
/* Pozadina modala */
.custom-modal {
    background-color: rgba(0, 0, 0, 0.8);
}

.custom-modal-content {
    background-color: #3b3b5c;
    /* Tamna pozadina */
    color: #ffffff;
    /* Svetli tekst */
    border-radius: 8px;
    max-width: 800px;
    width: 800px;
}

/* Stil za telo modala */
.custom-modal-body {
    padding: 20px;
}

/* Stil za tabelu unutar modala */
.custom-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #2c2c3e;
    /* Tamna pozadina tabele */
    color: rgba(255, 255, 255, 0.87);
    /* Svetlo sivi tekst */
}

.custom-table th {
    background-color: #3c3c4f;
    /* Tamna pozadina za zaglavlje */
    color: #ffffff;
    /* Beli tekst */
    padding: 10px;
    text-align: left;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.custom-table td {
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.87);
    /* Svetlo sivi tekst */
}

.custom-table tr:hover {
    background-color: rgba(255, 255, 255, 0.1);
    /* Istaknuta boja za hover */
}


.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Visina loadera */
    background-color: rgba(255, 255, 255, 0.7);
    /* Providna pozadina */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    /* Iznad ostalog sadržaja */
}

.loader {
    border: 8px solid #f3f3f3;
    /* Svetla boja */
    border-top: 8px solid #3498db;
    /* Boja animacije */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
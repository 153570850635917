<template>
  <modal
    :show.sync="localModalActive"
    class="modal"
    id="modal"
    modalClasses="custom-modal"
    modalContentClasses="custom-modal-content"
    bodyClasses="custom-modal-body"
    :centered="false"
    :show-close="true"
    @close="handleClose"
  >
    <card class="stacked-form" title="">
      <form @submit.prevent="submit">
        <div>
          <div class="row">
            <div class="col-12">
              <base-input v-model="parcel.name" label="Naziv" type="text">
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <base-input v-model="parcel.ip" label="IP" type="text">
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <base-input
                v-model="parcel.parcel_id"
                label="ID Paketomata"
                type="text"
              >
              </base-input>
            </div>
          </div>
        </div>

        <base-button native-type="submit" type="primary" class="mb-3" size="lg"
          >Sačuvaj</base-button
        >
      </form>
    </card>
  </modal>
</template>

<script>
import { BaseNav, Modal } from "@/components";
import axios from "@/util/axiosInstance";
import swal from "sweetalert2";

export default {
  name: "parcel-dialog",
  props: {
    isEdit: false,
    modalActive: {
      parcel: Boolean,
      default: false,
      sync: true,
    },
    parcel: {
      type: Object,
      default: () => ({
        name: "",
        ip: "",
        parcel_id: "",
      }),
    },
  },
  components: {
    BaseNav,
    Modal,
  },

  watch: {
    modalActive: function (newValue, oldValue) {
      this.localModalActive = newValue;
    },
  },

  data() {
    return {
      localModalActive: this.modalActive,
    };
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    async submit() {
      if (this.isEdit) {
        let result = await axios.patch(
          "/parcels/" + this.parcel.id,
          this.parcel
        );

        if (!result?.data?.errors) {
          swal
            .fire({
              icon: "success",
              title: "Uspešno ste ažurirali zapis",
            })
            .then((result) => {
              if (result.value) {
                this.$emit("submited");
              }
            });
        } else {
          await swal.fire({
            icon: "error",
            title: "Error",
            text: result?.data?.errors[0].message,
          });
        }
      } else {
        let result = await axios.post("/parcels", this.parcel);
        if (!result?.data?.errors) {
          swal
            .fire({
              icon: "success",
              title: "Uspešno ste sačuvali zapis",
            })
            .then((result) => {
              if (result.value) {
                this.$emit("submited");
              }
            });
        } else {
          await swal.fire({
            icon: "error",
            title: "Error",
            text: result?.data?.errors[0].message,
          });
        }
      }
    },
  },
};
</script>

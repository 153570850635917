<template>
    <div>
        <div v-if="loading" class="loader-container">
            <div class="loader"></div> <!-- Stilizovani loader -->
        </div>
        <div class="row">
            <div class="col-md-24">
                <card>
                    <template slot="header">
                        <h5 class="card-category"></h5>
                        <h3 class="card-title">Radni nalozi</h3>
                    </template>
                    <div class="row">
                        <div class="col-md-3">
                            <card>
                                <h4 slot="header" class="card-title">Početak</h4>
                                <base-input>
                                    <el-date-picker type="datetime" placeholder="Početak" value-format="timestamp"
                                        v-model="filter.start">
                                    </el-date-picker>
                                </base-input>
                            </card>
                        </div>
                        <div class="col-md-3">
                            <card>
                                <h4 slot="header" class="card-title">Kraj</h4>
                                <base-input>
                                    <el-date-picker type="datetime" placeholder="Kraj" value-format="timestamp"
                                        v-model="filter.end">
                                    </el-date-picker>
                                </base-input>
                            </card>
                        </div>

                        <div class="col-md-3">
                            <card>
                                <h4 slot="header" class="card-title">Smena</h4>
                                <base-input>
                                    <el-select v-model="filter.employeeShiftId" class="select-primary" clearable
                                        name="status">
                                        <el-option v-for="s in employeeShifts" class="select-primary" :label="s.name"
                                            :value="s.id" :key="s.id"></el-option>
                                    </el-select>
                                </base-input>
                            </card>
                        </div>

                        <div class="col-md-3">
                            <card>
                                <h4 slot="header" class="card-title"></h4>
                                <base-button class="mt-3" native-type="submit" style="margin-left: 20px;" type="primary"
                                    @click="getWorkOrders()">Pretraži</base-button>
                            </card>
                        </div>

                    </div>


                    <div class="row">
                        <div class="col-md-3">
                            <card>
                                <h4 slot="header" class="card-title"> </h4>
                                <base-button class="mt-3" native-type="submit" style="margin-left: 20px;" type="primary"
                                    @click="workOrder = {}; editWorkOrder = true">Novi nalog</base-button>
                            </card>
                        </div>
                    </div>
                    <el-table :data="workOrders" v-loading="ticketsLoading">
                        <el-table-column min-width="80" prop="name" label="Ime">
                            <template slot-scope="scope">
                                <span>{{ scope.row.employee?.name }} {{ scope.row.employee?.lastName }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="80" prop="shift" label="Smena">
                            <template slot-scope="scope">
                                <span>{{ scope.row.employeeShift?.name }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="80" prop="regionNames" label="Sektori">
                            <template slot-scope="scope">
                                <span>{{ scope.row.regionNames }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="80" prop="stampLong" label="Datum">
                            <template slot-scope="scope">
                                <span>{{ (new Date(scope.row.stampLong)).toLocaleDateString("de-DE", {
                                        year: "numeric",
                                        month: "2-digit", day: "2-digit"
                                    }) }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="100" header-align="right" align="right" label="Actions">
                            <div slot-scope="scope" class="text-right table-actions">

                                <base-button :type="'success'" class="btn-link"
                                    @click="workOrder = scope.row; editWorkOrder = true">
                                    Izmeni
                                </base-button>

                                <base-button :type="'danger'" class="btn-link"
                                    @click="workOrder = scope.row; deleteWorkOrderPrompt()">
                                    Obriši
                                </base-button>

                            </div>
                        </el-table-column>
                    </el-table>
                </card>
            </div>


        </div>
        <modal :show.sync="editWorkOrder" :show-close="true" :scrollToBottom="true" :appendToBody="true"
            modalClasses="custom-modal" modalContentClasses="custom-modal-content" bodyClasses="custom-modal-body">
            <base-button :type="'danger'" icon size="sm" class="btn-link"
                @click="editWorkOrder = false; workOrder = {}">
                <i class="tim-icons icon-simple-remove"></i>
            </base-button>


            <card class="stacked-form" title="Radni nalog">
                <form @submit.prevent>
                    <div>
                        <div class="row">
                            <div class="col-md-3">
                                <base-input label="Kontrolor">
                                    <el-select v-model="workOrder.employeeId" class="select-primary" name="employeeId">
                                        <el-option v-for="t in employees" class="select-primary"
                                            :label="t.name + ' ' + t.lastName" :value="t.id" :key="t.id"></el-option>
                                    </el-select>
                                </base-input>
                            </div>

                            <div class="col-md-3">
                                <base-input label="Sektori">
                                    <el-select v-model="workOrder.regions" multiple class="select-primary"
                                        name="regions" value-key="id">
                                        <el-option v-for="t in regions" class="select-primary" :label="t.name"
                                            :value="t" :key="t.id"></el-option>
                                    </el-select>
                                </base-input>
                            </div>
                            <div class="col-md-3">
                                <base-input label="Smena">
                                    <el-select v-model="workOrder.employeeShiftId" class="select-primary"
                                        name="employeeShiftId">
                                        <el-option v-for="t in employeeShifts" class="select-primary" :label="t.name"
                                            :value="t.id" :key="t.id"></el-option>
                                    </el-select>
                                </base-input>
                            </div>

                            <div class="col-md-3">
                                <base-input label="Datum">
                                    <el-date-picker type="date" placeholder="Datum" value-format="timestamp"
                                        v-model="workOrder.stampLong">
                                    </el-date-picker>
                                </base-input>
                            </div>
                        </div>

                    </div>
                </form>

            </card>


            <base-button class="mt-3" native-type="submit" style="margin-left: 20px;" type="primary"
                @click="saveWorkOrder()">Sačuvaj</base-button>
            <base-button class="mt-3" native-type="submit" style="margin-left: 20px;" type="danger"
                @click="editWorkOrder = false; workOrder = {}">Odustani</base-button>

        </modal>


    </div>
</template>
<script>
import { TabPane, Tabs, Collapse, CollapseItem } from 'src/components';
import { Table, TableColumn, TimeSelect, DatePicker, Select, Option } from 'element-ui';
import { BaseCheckbox, BaseProgress, RouteBreadCrumb } from 'src/components';
import axios from 'axios'
import swal from 'sweetalert2';
import { BaseNav, Modal } from '@/components';
import htmlDocx from 'html-docx-js';
import { saveAs } from 'file-saver';


export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [DatePicker.name]: DatePicker,
        [TimeSelect.name]: TimeSelect,
        [Option.name]: Option,
        [Select.name]: Select,
        TabPane,
        Tabs,
        Collapse,
        CollapseItem,
        Modal
    },
    mounted() {
        this.getWorkOrders()
        this.getEmployeeShifts()
        this.getEmployees()
        this.getRegions()
        // this.getClients()
    },
    data() {
        const now = new Date();

        // Postavljanje startStamp na 00:00:00 danas
        const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0).getTime();

        // Postavljanje endStamp na 23:59:59 danas
        const endDate = new Date(now); // Kopija trenutnog datuma
        endDate.setDate(now.getDate() + 7); // Dodajemo 7 dana
        endDate.setHours(23, 59, 59, 999); // Postavljamo poslednju sekundu dana
        const end = endDate.getTime();

        return {
            url: 'https://hercegnovi.scancar.org:8443/kuca',
            ticketsLoading: false,
            loading: false,
            workOrders: [],
            workOrder: {},
            editWorkOrder: false,
            filter: {
                start: start,
                end: end,
                employeeShiftId: null,
                employeeId: null,
                regionId: null
            },
            employeeShifts: [],
            employees: [],
            regions: []
        };
    },
    methods: {
        getEmployees() {
            axios.get(this.url + '/employee/getEmployees').then(result => {
                if (result.data.result === 'ok') {
                    this.employees = result.data.employees
                } else {

                }
            })
        },
        getRegions() {
            // this.zonesLoading = true
            axios.get(this.url + '/settings/getRegions').then(result => {
                // this.zonesLoading = false

                if (result.data.result === 'ok') {
                    this.regions = result.data.regions
                } else {

                }
            })
        },
        getWorkOrders() {
            this.ticketsLoading = true
            axios.post(this.url + '/employee/getWorkOrders', this.filter).then(result => {
                this.ticketsLoading = false

                if (result.data.result === 'ok') {
                    this.workOrders = result.data.workOrders
                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        getEmployeeShifts() {
            this.ticketsLoading = true
            axios.get(this.url + '/employee/getEmployeeShifts').then(result => {
                this.ticketsLoading = false

                if (result.data.result === 'ok') {
                    this.employeeShifts = result.data.employeeShifts
                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        saveWorkOrder() {
            this.loading = true
            axios.post(this.url + '/employee/saveWorkOrder', this.workOrder).then(result => {
                this.loading = false

                if (result.data.result === 'ok') {
                    this.$notify({
                        message:
                            'Sacuvano',
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'success'
                    });
                    this.editWorkOrder = false
                    this.workOrder = {}
                    this.getWorkOrders()
                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        deleteWorkOrder() {
            this.loading = true
            axios.post(this.url + '/employee/deleteWorkOrder', this.workOrder).then(result => {
                this.loading = false

                if (result.data.result === 'ok') {
                    this.$notify({
                        message:
                            'Obrisano',
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'success'
                    });
                    this.getWorkOrders()
                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        deleteWorkOrderPrompt() {
            const swalWithBootstrapButtons4 = swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success btn-fill",
                    cancelButton: "btn btn-danger btn-fill",
                },
                buttonsStyling: false,
            });
            swalWithBootstrapButtons4
                .fire({
                    title: "Da li ste sigurni da želite da obrišete radni nalog?",
                    text: ``,
                    confirmButtonText: "Da, obriši!",
                    cancelButtonText: "NE",
                    showCancelButton: true,
                })
                .then((result) => {
                    if (result.value) {
                        this.deleteWorkOrder()
                        // const swalWithBootstrapButtons5 = swal.mixin({
                        //     customClass: {
                        //     confirmButton: "btn btn-success btn-fill",
                        //     },
                        //     buttonsStyling: false,
                        // });
                        // swalWithBootstrapButtons5.fire({
                        //     title: "Deleted!",
                        //     text: "Your file has been deleted.",
                        // });
                    }
                });
        },
        // saveTicketPrompt() {
        //     const swalWithBootstrapButtons4 = swal.mixin({
        //         customClass: {
        //             confirmButton: "btn btn-success btn-fill",
        //             cancelButton: "btn btn-danger btn-fill",
        //         },
        //         buttonsStyling: false,
        //     });

        //     swalWithBootstrapButtons4
        //         .fire({
        //             title: "Molimo vas unesite komentar za ovu promenu.",
        //             html: `<textarea id="comment" class="swal2-textarea" placeholder="Komentar" style="width: 100%; height: 100px;"></textarea>`,
        //             confirmButtonText: "Sačuvaj",
        //             cancelButtonText: "Odustani",
        //             showCancelButton: true,
        //             preConfirm: () => {
        //                 const comment = document.getElementById("comment").value;
        //                 // if (!comment.trim()) {
        //                 //     Swal.showValidationMessage("Molimo unesite komentar pre nego što nastavite!");
        //                 // }
        //                 return comment;
        //             },
        //         })
        //         .then((result) => {
        //             if (result.isConfirmed) {
        //                 const comment = result.value; // Dohvaćen komentar
        //                 console.log("Komentar:", comment); // Možete proslediti komentar funkciji
        //                 // this.deleteParkingSpace(comment);
        //                 this.ticket.comment = comment;
        //                 this.saveTicket()
        //             }
        //         });
        // }

    }
};
</script>
<style lang="scss">
/* Pozadina modala */
.custom-modal {
    background-color: rgba(0, 0, 0, 0.8);
}

.custom-modal-content {
    background-color: #3b3b5c;
    /* Tamna pozadina */
    color: #ffffff;
    /* Svetli tekst */
    border-radius: 8px;
    max-width: 800px;
    width: 800px;
}

/* Stil za telo modala */
.custom-modal-body {
    padding: 20px;
}

/* Stil za tabelu unutar modala */
.custom-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #2c2c3e;
    /* Tamna pozadina tabele */
    color: rgba(255, 255, 255, 0.87);
    /* Svetlo sivi tekst */
}

.custom-table th {
    background-color: #3c3c4f;
    /* Tamna pozadina za zaglavlje */
    color: #ffffff;
    /* Beli tekst */
    padding: 10px;
    text-align: left;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.custom-table td {
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.87);
    /* Svetlo sivi tekst */
}

.custom-table tr:hover {
    background-color: rgba(255, 255, 255, 0.1);
    /* Istaknuta boja za hover */
}


.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Visina loadera */
    background-color: rgba(255, 255, 255, 0.7);
    /* Providna pozadina */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    /* Iznad ostalog sadržaja */
}

.loader {
    border: 8px solid #f3f3f3;
    /* Svetla boja */
    border-top: 8px solid #3498db;
    /* Boja animacije */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
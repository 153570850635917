import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'https://admin.hotelsync.org/pms/api/v1', // Zameni sa svojim API URL-om
});

// Dodaj interceptor za svaki request
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token'); // Preuzmi token iz localStorage-a ili drugog mesta
        // console.log("TOKEN: "+token);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        const tenant = localStorage.getItem('tenant'); // Preuzmi token iz localStorage-a ili drugog mesta
        // console.log("TENANT: "+tenant);
        if (tenant) {
            config.headers.tenant = tenant;
        }
        config.headers['Cache-Control'] = 'no-cache';
        config.headers['Pragma'] = 'no-cache'; // Opcionalno, za dodatnu kompatibilnost sa starijim serverima
        config.headers['Expires'] = '0';
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
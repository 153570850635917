<template>
    <div class="row">
        <!-- Big Chart -->
        <div class="col-12">
            <card>
                <el-select v-model="client" class="select-primary" name="role" value-key="id" @change="getParkings()" clearable>
                    <el-option v-for="p in clients" class="select-primary" :label="p.name" :value="p"
                        :key="p.id"></el-option>
                </el-select>
                <el-select :disabled="!client.id" v-model="parking" class="select-primary" name="role" value-key="id" clearable>
                    <el-option v-for="p in parkings" class="select-primary" :label="p.name" :value="p"
                        :key="p.id"></el-option>
                </el-select>
                <base-button class="mt-3" native-type="submit" style="margin-left: 20px;" type="primary"
                    @click="getPolygons()">PRETRAZI</base-button>

                <base-button class="mt-3" native-type="submit" style="margin-left: 20px;" type="danger"
                    @click="savePolygons()">SACUVAJ</base-button>
            </card>
            <card>

                <l-map ref="map" style="height: 100vh; width: 100%;" :zoom="zoom" :center="center" @ready="onMapReady">
                    <l-tile-layer :url="mapUrl" :attribution="attribution" />
                    <l-polygon v-for="(polygon, index) in polygons" :key="index" :lat-lngs="polygon.latlngs"
                        :color="polygon.parking.client.color" :weight="3" :opacity="0.6" />
                </l-map>
            </card>
        </div>
    </div>
</template>
<script>
import LineChart from '@/components/Charts/LineChart';
import BarChart from '@/components/Charts/BarChart';
import * as chartConfigs from '@/components/Charts/config';
import StatsCard from 'src/components/Cards/StatsCard';
import config from '@/config';
import axios from 'axios';
import { LMap, LTileLayer, LMarker, LPopup, LPolygon } from "vue2-leaflet";
import 'leaflet/dist/leaflet.css';
import { TimeSelect, Table, TableColumn, Select, Option } from 'element-ui';
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw";



import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// let bigChartData = [
//   [100, 70, 90, 70, 85, 60, 75],
//   [80, 120, 105, 110, 95, 105, 90],
//   [60, 80, 65, 130, 80, 105, 90]
// ]
// let bigChartLabels = ['PON', 'UTO', 'SRE', 'ČET', 'PET', 'SUB', 'NED']
// let bigChartDatasetOptions = {
//   fill: true,
//   borderColor: config.colors.primary,
//   borderWidth: 2,
//   borderDash: [],
//   borderDashOffset: 0.0,
//   pointBackgroundColor: config.colors.primary,
//   pointBorderColor: 'rgba(255,255,255,0)',
//   pointHoverBackgroundColor: config.colors.primary,
//   pointBorderWidth: 20,
//   pointHoverRadius: 4,
//   pointHoverBorderWidth: 15,
//   pointRadius: 4,
// }

export default {
    components: {
        LineChart,
        BarChart,
        StatsCard,
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        LPolygon,
        [Option.name]: Option,
        [Select.name]: Select,
    },
    data() {
        return {
            customIcon: L.divIcon({
                className: "icon-delivery-fast", // CSS klasa za prilagođeni stil ikone
                iconSize: [30, 30], // Dimenzije (ako je potrebno)
            }),
            center: [42.4533932, 18.5291398], // Koordinate (lat, lng)
            vehiclePosition: [42.4533932, 18.5291398], // Koordinate (lat, lng)
            vehicles: [],
            parkings: [],
            parking: {},
            clients: [],
            client: {},
            polygons: [],
            zoom: 15,
            mapUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            attribution:
                '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',

            url: 'https://hercegnovi.scancar.org:8443/kuca',
            drawnItems: null,
            newPolygons: []
        };
    },
    computed: {

    },
    methods: {
        onMapReady() {
            const map = this.$refs.map.mapObject; // Pristup Leaflet mapi

            // Dodavanje FeatureGroup za crtanje slojeva
            this.drawnItems = new L.FeatureGroup();
            map.addLayer(this.drawnItems);

            // Dodavanje Leaflet.Draw kontrola
            const drawControl = new L.Control.Draw({
                edit: {
                    featureGroup: this.drawnItems, // Grupisanje objekata za editovanje
                    remove: true, // Omogućavanje brisanja
                },
                draw: {
                    polygon: true, // Omogućavanje crtanja poligona
                    rectangle: false,
                    circle: false,
                    marker: false,
                    polyline: false,
                },
            });
            map.addControl(drawControl);

            // Event: Dodavanje novog poligona
            map.on("draw:created", (event) => {
                const layer = event.layer;
                this.drawnItems.addLayer(layer);
                console.log("Poligon dodat:", layer.getLatLngs());
                const coordinates = layer.getLatLngs()[0].map((latLng) => [latLng.lat, latLng.lng]);

                // Dodavanje u niz newPolygons
                this.newPolygons.push(coordinates);
            });

            // Event: Editovanje postojećeg poligona
            map.on("draw:edited", (event) => {
                const layers = event.layers;
                layers.eachLayer((layer) => {
                    console.log("Poligon izmenjen:", layer.getLatLngs());
                });
            });

            // Event: Brisanje poligona
            map.on("draw:deleted", (event) => {
                const layers = event.layers;
                layers.eachLayer((layer) => {
                    console.log("Poligon obrisan:", layer.getLatLngs());
                });
            });
        },
        getParkings() {
            this.loading = true
            axios.get(this.url + '/client/getParkings?clientId='+this.client.id).then(result => {
                this.loading = false

                if (result.data.result === 'ok') {
                    this.parkings = result.data.parkings

                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        getClients() {
            this.loading = true
            axios.get(this.url + '/client/getClients').then(result => {
                this.loading = false

                if (result.data.result === 'ok') {
                    this.clients = result.data.clients

                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        getPolygons() {
            this.loading = true
            // this.drawnItems = null
            
            this.newPolygons = []
            // this.onMapReady()
            let body = this.parking
            if(!body.id){
                body = {
                    clientId: this.client.id
                }
            }
            axios.post(this.url + '/client/getParkingPolygons', body).then(result => {
                this.loading = false

                if (result.data.result === 'ok') {
                    this.polygons = result.data.polygons

                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        savePolygons() {
            this.loading = true
            console.log(this.newPolygons);
            this.parking.newPolygons = this.newPolygons
            axios.post(this.url + '/client/savePolygons', this.parking).then(result => {
                this.loading = false

                if (result.data.result === 'ok') {
                    this.getPolygons()
                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        }
    },
    mounted() {
        this.getClients()
    },
    beforeDestroy() {

    }
};
</script>
<style>
.leaflet-container {
    height: 100%;
    width: 100%;
}
</style>
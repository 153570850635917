<template>
  <el-table
    :data="hotels"
    style="width: 100%"
    default-expand-all
    :page-size="pageSize"
    :current-page="currentPage"
    @current-change="handleCurrentChange"
  >
    <el-table-column type="expand">
      <template slot-scope="scope">
        <div
          class="ml-0 mr-0 ml-md-3 mr-md-3"
          v-for="(type, index) in scope.row.types"
          :key="index"
        >
          <card>
            <h3 slot="header" class="pl-3 pt-3">
              <i class="el-icon-files"></i> Tip sobe
              <i class="el-icon-right ml-2 mr-2"></i> {{ type.name }}
            </h3>
            <div class="row mr-0 ml-0 mr-md-3 ml-md-3" style="padding: 10px">
              <div
                class="col-12 col-md-6 col-lg-3 text-center"
                v-for="(room, roomIndex) in type.hroom"
                :key="roomIndex"
              >
                <card class="border shadow-xl">
                  <h3 v-if="!room.action_id">
                    Soba <i class="el-icon-right ml-2 mr-2"></i> {{ room.name }}
                  </h3>
                  <h3 v-if="room.action_id" class="text-success">
                    Soba <i class="el-icon-right ml-2 mr-2"></i> {{ room.name }}
                  </h3>
                  <!-- <h3>
                    <span v-if="room.action_id" class="text-success"
                      ><i class="el-icon-check"></i> OTVORENO</span
                    ><span v-if="!room.action_id" class="text-danger"
                      ><i class="el-icon-close"></i> ZATVORENO</span
                    >
                  </h3> -->

                  <!-- <h3>
                    <span
                      v-if="room?.action?.info?.keyInside"
                      class="text-success"
                    >
                      <i class="el-icon-key text-success"></i></span
                    ><span
                      v-if="!room?.action?.info?.keyInside"
                      class="text-danger"
                    >
                      <i class="el-icon-key text-danger"></i>
                    </span>
                  </h3> -->

                  <div class="row">
                    <div class="col-12 mt-3">
                      <base-button
                        v-if="!room.action_id"
                        class="col-12 p-3"
                        type="primary"
                        :disabled="buttonDisabled"
                        @click="openCloseRoom(true, room, scope.row)"
                        >Otvori</base-button
                      >
                      <base-button
                        v-if="room.action_id"
                        class="col-12 p-3"
                        type="warning"
                        :disabled="buttonDisabled"
                        @click="openCloseRoom(false, room, scope.row)"
                        >Zatvori</base-button
                      >
                    </div>

                    <!-- <div class="col-12 mt-3">
                      <base-button
                        v-if="!room?.action?.info?.keyInside"
                        class="col-12 p-3 bg-success"
                        type="success"
                        :disabled="buttonDisabled"
                        @click="keyInsideOut(true, room)"
                        >Ključ je unutra</base-button
                      >
                    </div>

                    <div v-if="room?.action?.info?.keyInside" class="col-12">
                      <base-button
                        class="col-12 p-3"
                        type="danger"
                        :disabled="buttonDisabled"
                        @click="keyInsideOut(false, room)"
                        >Ključ nije unutra</base-button
                      >
                    </div> -->
                  </div>
                </card>
              </div>
            </div>
          </card>
        </div>
      </template>
    </el-table-column>
    <el-table-column min-width="150" prop="name" label="">
      <template slot-scope="scope">
        <h2 class="mt-4">
          <i class="el-icon-school"></i> Hotel
          <i class="el-icon-right ml-2 mr-2"></i> {{ scope.row.name }}
          <i class="el-icon-right ml-2 mr-2"></i>
          <span class="text-success"> Online</span>
        </h2>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { Table, TableColumn } from "element-ui";
import axios from "@/util/axiosInstance";
import swal from "sweetalert2";

export default {
  components: {
    "el-table": Table,
    "el-table-column": TableColumn,
  },
  data() {
    return {
      buttonDisabled: false,
      keyInside: false,
      search: "",
      typesLoading: false,
      hotels: [],
      pageSize: 10,
      currentPage: 1,
      total: 0,
      tableData: [
        {
          date: "2016-05-03",
          name: "Tom",
          state: "California",
          city: "Los Angeles",
          address: "No. 189, Grove St, Los Angeles",
          zip: "CA 90036",
        },
        {
          date: "2016-05-02",
          name: "Tom",
          state: "California",
          city: "Los Angeles",
          address: "No. 189, Grove St, Los Angeles",
          zip: "CA 90036",
        },
        {
          date: "2016-05-04",
          name: "Tom",
          state: "California",
          city: "Los Angeles",
          address: "No. 189, Grove St, Los Angeles",
          zip: "CA 90036",
        },
        {
          date: "2016-05-01",
          name: "Tom",
          state: "California",
          city: "Los Angeles",
          address: "No. 189, Grove St, Los Angeles",
          zip: "CA 90036",
        },
        {
          date: "2016-05-08",
          name: "Tom",
          state: "California",
          city: "Los Angeles",
          address: "No. 189, Grove St, Los Angeles",
          zip: "CA 90036",
        },
        {
          date: "2016-05-06",
          name: "Tom",
          state: "California",
          city: "Los Angeles",
          address: "No. 189, Grove St, Los Angeles",
          zip: "CA 90036",
        },
        {
          date: "2016-05-07",
          name: "Tom",
          state: "California",
          city: "Los Angeles",
          address: "No. 189, Grove St, Los Angeles",
          zip: "CA 90036",
        },
      ],
    };
  },

  mounted() {
    this.getHotels();
  },

  methods: {
    async keyInsideOut(action, room) {
      this.buttonDisabled = true;
      if (!room?.box) {
        await swal.fire({
          title: "Upozorenje",
          text: "Soba nema povezan boks",
          icon: "warning",
        });
      } else {
        if (room.action?.info) {
          let data = room.action?.info;
          data.keyInside = action;

          let result = await axios.patch("/actions/" + room.action.id, {
            info: data,
          });
          if (result?.data?.id) {
            let swalResult = await swal.fire({
              icon: "success",
              title: "Uspešno ste otvorili",
            });
            if (swalResult.value) {
              await this.getHotels();
            }
            // let roomActionResult = await axios.patch(
            //   "/rooms/" + room?.id + "/action",
            //   { action_id: result.data?.id }
            // );
            // if (!roomActionResult?.data?.id) {
            //   let swalResult = await swal.fire({
            //     icon: "success",
            //     title: "Uspešno ste otvorili",
            //   });
            //   if (swalResult.value) {
            //     await this.getHotels();
            //   }
            // }
          }
        } else {
          await swal.fire({
            title: "Upozorenje",
            text: "Soba nema otvoren boks",
            icon: "warning",
          });
        }
      }

      this.buttonDisabled = false;
    },
    async openCloseRoom(action, room, hotel) {
      this.buttonDisabled = true;
      if (action) {
        let hotelId = hotel.id;
        if (!room?.box) {
          await swal.fire({
            title: "Upozorenje",
            text: "Soba nema povezan boks",
            icon: "warning",
          });
        } else {
          //   room.box["keyInside"] = this.keyInside;
          // room.box["hotel_id"] = hotelId;
          // room.box["room_id"] = room.id;
          let actionResult = await axios.post("/actions", { info: room.box });
          if (actionResult.data?.id) {
            let result = await swal.fire({
              icon: "success",
              title: "Uspešno ste otvorili",
            });
            if (result.value) {
              await this.getHotels();
            }
            // let roomActionResult = await axios.patch(
            //   "/rooms/" + room?.id + "/action",
            //   { action_id: actionResult.data?.id }
            // );
            // if (!roomActionResult?.data?.id) {
            //   let result = await swal.fire({
            //     icon: "success",
            //     title: "Uspešno ste otvorili",
            //   });
            //   if (result.value) {
            //     await this.getHotels();
            //   }
            // }
          }
        }
      } else {
        // let roomActionResult = await axios.patch(
        //   "/rooms/" + room?.id + "/action",
        //   { action_id: null }
        // );
        // if (!roomActionResult?.data?.id) {
        //   let result = await swal.fire({
        //     icon: "success",
        //     title: "Uspešno ste zatvorili",
        //   });
        //   if (result.value) {
        //     await this.getHotels();
        //   }
        // }
      }
      this.buttonDisabled = false;
    },
    async getHotels() {
      this.zonesLoading = true;
      let result = await axios.get(
        "/hotels?skip=" +
          this.currentPage +
          "&limit=" +
          this.pageSize +
          "" +
          "&search=" +
          this.search
      );

      if (result.data.results) {
        this.typesLoading = false;
        this.hotels = result.data.results;
        this.total = result.data.total;
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTypes();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTypes();
    },
  },
};
</script>

<template>
  <modal
    :show.sync="localModalActive"
    class="modal"
    id="modal"
    modalClasses="custom-modal"
    modalContentClasses="custom-modal-content"
    bodyClasses="custom-modal-body"
    :centered="false"
    :show-close="true"
    @close="handleClose"
  >
    <card class="stacked-form" title="">
      <form @submit.prevent="submit">
        <div>
          <div class="row">
            <div class="col-12">
              <base-input v-model="room.name" label="Naziv" type="text">
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <base-input v-model="room.description" label="Opis" type="text">
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <base-input
                v-model="room.beds24_room_id"
                label="Beds24 ID"
                type="text"
              >
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <base-input
                v-model="room.oteSync_type_id"
                label="Otasync Type ID"
                type="text"
              >
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <base-input label="Boks">
                <el-select
                  @focus="getBoxes()"
                  v-model="box"
                  class="select-primary"
                  clearable
                  value-key="id"
                  placeholder="Izaberi boks"
                >
                  <el-option
                    v-for="p in boxes"
                    class="select-primary"
                    :label="p.name"
                    :value="p"
                    :key="p.id"
                  ></el-option>
                </el-select>
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <base-input label="Brava">
                <el-select
                  @focus="getLocks()"
                  v-model="lock"
                  class="select-primary"
                  clearable
                  value-key="id"
                  placeholder="Izaberi bravu"
                >
                  <el-option
                    v-for="p in locks"
                    class="select-primary"
                    :label="p.name"
                    :value="p"
                    :key="p.id"
                  ></el-option>
                </el-select>
              </base-input>
            </div>
          </div>
        </div>

        <base-button native-type="submit" type="primary" class="mb-3" size="lg"
          >Sačuvaj</base-button
        >
      </form>
    </card>
  </modal>
</template>

<script>
import { BaseNav, Modal } from "@/components";
import axios from "@/util/axiosInstance";
import swal from "sweetalert2";
import {
  Table,
  TableColumn,
  TimeSelect,
  DatePicker,
  Select,
  Option,
} from "element-ui";

export default {
  name: "room-dialog",
  props: {
    isEdit: false,
    modalActive: {
      room: Boolean,
      default: false,
      sync: true,
    },
    room: {
      type: Object,
      default: () => ({
        name: "",
        description: "",
        beds24_room_id: null,
        oteSync_type_id: null,
        box_id: 0,
        lock_id: 0,
      }),
    },
  },
  components: {
    BaseNav,
    Modal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
  },

  watch: {
    room: function (newValue, oldValue) {
      if (newValue?.box_id && this.isEdit) {
        this.box = this.boxes.find((p) => p.id === newValue.box_id);
      } else {
        this.box = {};
      }
      if (newValue?.lock_id && this.isEdit) {
        this.lock = this.locks.find((p) => p.id === newValue.lock_id);
      } else {
        this.lock = {};
      }
    },
    modalActive: function (newValue, oldValue) {
      this.localModalActive = newValue;
    },
  },

  mounted() {
    this.getBoxes();
    this.getLocks();
  },

  data() {
    return {
      localModalActive: this.modalActive,
      box: {},
      boxes: [],
      lock: {},
      locks: [],
    };
  },
  methods: {
    async getBoxes() {
      let result = await axios.get("/boxes?limit=900");

      if (result?.data?.results) {
        this.boxes = result.data.results;
      }
    },
    async getLocks() {
      let result = await axios.get("/locks?limit=900");

      if (result?.data?.results) {
        this.locks = result.data.results;
      }
    },
    handleClose() {
      this.$emit("close");
    },
    async submit() {
      this.room.box_id = this.box.id;
      this.room.lock_id = this.lock.id;

      if (this.room.beds24_room_id == "") {
        this.room.beds24_room_id = null;
      }
      if (this.room.oteSync_type_id == "") {
        this.room.oteSync_type_id = null;
      }

      if (this.isEdit) {
        let result = await axios.patch("/rooms/" + this.room.id, this.room);

        if (!result?.data?.errors) {
          swal
            .fire({
              icon: "success",
              title: "Uspešno ste ažurirali zapis",
            })
            .then((result) => {
              if (result.value) {
                this.$emit("submited");
              }
            });
        } else {
          await swal.fire({
            icon: "error",
            title: "Error",
            text: result?.data?.errors[0].message,
          });
        }
      } else {
        let result = await axios.post("/rooms", this.room);
        if (!result?.data?.errors) {
          swal
            .fire({
              icon: "success",
              title: "Uspešno ste sačuvali zapis",
            })
            .then((result) => {
              if (result.value) {
                this.$emit("submited");
              }
            });
        } else {
          await swal.fire({
            icon: "error",
            title: "Error",
            text: result?.data?.errors[0].message,
          });
        }
      }
    },
  },
};
</script>

<template>
    <div>
        <div v-if="loading" class="loader-container">
            <div class="loader"></div> <!-- Stilizovani loader -->
        </div>
        <div class="row">
            <div class="col-md-24">
                <card>
                    <template slot="header">
                        <h5 class="card-category"></h5>
                        <base-button :type="'success'" icon size="sm" class="btn-link"
                            @click="navigateToPage()"
                        >
                            <i class="tim-icons icon-double-left"></i>
                        </base-button>
                        <h3 class="card-title">Lokacije za zonu: {{ zoneName }}</h3>
                    </template>

                    <el-table :data="parkings">
                        <el-table-column min-width="100" prop="active" label="Status">
                            <template slot-scope="scope">
                                <span v-if="scope.row.active" style="background-color: green;">Aktivan</span>
                                <span v-else style="background-color: red;">Nektivan</span>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="100" prop="costCenter" label="Oznaka">
                            <template slot-scope="scope">
                                <span>{{ scope.row.costCenter }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="250" prop="name" label="Ime">
                            <template slot-scope="scope">
                                <span :style="{ color: scope.row.color }">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="100" prop="spacesCnt" label="Aktivna mesta">
                            <template slot-scope="scope">
                                <span>{{ scope.row.spacesCnt }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="100" prop="spacesCntNotActive" label="Neaktivna mesta">
                            <template slot-scope="scope">
                                <span>{{ scope.row.spacesCntNotActive }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="100" prop="parkingTypeName" label="Tip">
                            <template slot-scope="scope">
                                <span>{{ scope.row.parkingTypeName }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="100" prop="regionName" label="Sektor">
                            <template slot-scope="scope">
                                <span>{{ scope.row.regionName }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="100" prop="productName" label="Mikrozona">
                            <template slot-scope="scope">
                                <span>{{ scope.row.productName }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="200" header-align="right" align="right" label="Actions">
                            <div slot-scope="scope" class="text-right table-actions">
                                
                                <base-button :type="'success'" class="btn-link"
                                    @click="parking = scope.row; getParkingSpaces()">
                                    Parking mesta
                                </base-button>
                                
                                <el-tooltip content="Izmeni" effect="light" :open-delay="300" placement="top">
                                    <base-button :type="'success'" icon size="sm" class="btn-link">
                                        <i class="tim-icons icon-pencil"></i>
                                    </base-button>
                                </el-tooltip>
                                <el-tooltip content="Obriši" effect="light" :open-delay="300" placement="top">
                                    <base-button :type="'danger'" icon size="sm" class="btn-link"
                                        @click="zone = scope.row; deletePrompt()">
                                        <i class="tim-icons icon-simple-remove"></i>
                                    </base-button>
                                </el-tooltip>
                            </div>
                        </el-table-column>
                    </el-table>
                </card>
            </div>
            
            
        </div>
        <modal :show.sync="showParkingSpaces"
                
                :show-close="true"
                :scrollToBottom="true"
                :appendToBody="true"
                modalClasses="custom-modal"
                modalContentClasses="custom-modal-content"
                bodyClasses="custom-modal-body"
                >
                    <base-button :type="'danger'" icon size="sm" class="btn-link"
                        @click="showParkingSpaces = false; parking = {}">
                        <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                    <el-table :data="parkingSpaces">
                        <el-table-column min-width="100" prop="number" label="Broj parking mesta">
                            <template slot-scope="scope">
                                <span>
                                    <!-- {{ scope.row.number }} -->
                                    <i v-if="!scope.row.id" class="tim-icons icon-minimal-down"></i>
                                    <base-input
                                        v-model="scope.row.number"
                                        style="width: 100px"
                                    >
                                    </base-input>
                                </span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="100" prop="active" label="Aktivno">
                            <template slot-scope="scope">
                                <span>
                                    <base-checkbox class="text-left" v-model="scope.row.active">
                                    
                                    </base-checkbox>
                                </span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="100" prop="active" label="Za invalide">
                            <template slot-scope="scope">
                                <span>
                                    <base-checkbox class="text-left" v-model="scope.row.forDisabled">
                                    
                                    </base-checkbox>
                                </span>
                            </template>
                        </el-table-column>


                        <el-table-column min-width="150" header-align="right" align="right" label="Actions">
                            <div slot-scope="scope" class="text-right table-actions">
                                <base-button :type="'success'" class="btn-link"
                                        @click="saveParkingSpace(scope.row)">
                                        Sačuvaj
                                    </base-button>
                                <el-tooltip content="Obriši" effect="light" :open-delay="300" placement="top">
                                    <base-button :type="'danger'" icon size="sm" class="btn-link"
                                        @click="parkingSpace = scope.row; deletePrompt()">
                                        <i class="tim-icons icon-simple-remove"></i>
                                    </base-button>
                                </el-tooltip>
                            </div>
                        </el-table-column>
                    </el-table>
                    <base-button :type="'success'" size="sm" class="btn-link"
                        @click="addSpace()">
                        Dodaj parking mesto
                    </base-button>
                    <base-button :type="'danger'" size="sm" class="btn-link"
                        @click="showParkingSpaces = false; parking = {}">
                        Zatvori
                    </base-button>
            </modal>
    </div>
</template>
<script>
import { TabPane, Tabs, Collapse, CollapseItem } from 'src/components';
import { Table, TableColumn } from 'element-ui';
import { BaseCheckbox, BaseProgress, RouteBreadCrumb } from 'src/components';
import axios from 'axios'
import swal from 'sweetalert2';
import { BaseNav, Modal } from '@/components';

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        TabPane,
        Tabs,
        Collapse,
        CollapseItem,
        Modal
    },
    mounted() {
        this.getParkings(this.$route.params.id)
    },
    data() {
        return {
            activeName: 'first',
            zones: [],
            url: 'https://hercegnovi.scancar.org:8443/kuca',
            parkings: [],
            zoneName: '',
            parking: {},
            parkingSpaces: [],
            parkingSpace: {},
            showParkingSpaces: false,
            loading: false
        };
    },
    methods: {
        setSpaceActive(space){
            console.log(space);
        }, 
        addSpace(){
            const space = {}
            space.number = 0
            space.forDisabled = false
            space.active = true
            space.parking = this.parking

            this.parkingSpaces.push(space)
        },
        getParkings(id) {
            axios.get(this.url + '/client/getParkings?clientId=' + id).then(result => {
                this.loading = false

                if (result.data.result === 'ok') {
                    this.parkings = result.data.parkings
                    this.zoneName = result.data.zoneName
                    this.parkingsModal = true
                } else {
                    this.$notify({
                        message:
                        result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        getParkingSpaces() {
            axios.get(this.url + '/client/getParkingSpaces?parkingId=' + this.parking.id).then(result => {
                this.loading = false

                if (result.data.result === 'ok') {
                    this.parkingSpaces = result.data.parkingSpaces
                    this.showParkingSpaces = true
                } else {
                    this.$notify({
                        message:
                        result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        saveParkingSpace(space) {
            this.loading = true
            axios.post(this.url + '/client/saveParkingSpace', space).then(result => {
                this.loading = false

                if (result.data.result === 'ok') {
                    this.$notify({
                        message:
                        'Sacuvano',
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'success'
                    });
                    this.getParkingSpaces()
                } else {
                    this.$notify({
                        message:
                        result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        deleteParkingSpace() {
            this.loading = true
            axios.post(this.url + '/client/deleteParkingSpace', this.parkingSpace).then(result => {
                this.loading = false
                // const space = this.parkingSpace
                if (result.data.result === 'ok') {
                    this.parkingSpaces = this.parkingSpaces.filter(space => space.number !== this.parkingSpace.number);
                    this.$notify({
                        message:
                        'Obrisano',
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'success'
                    });
                } else {
                    this.$notify({
                        message:
                        result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        navigateToPage() {
            this.$router.push({ name: 'Administration' });
        },
        deletePrompt() {
            const swalWithBootstrapButtons4 = swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success btn-fill",
                    cancelButton: "btn btn-danger btn-fill",
                },
                buttonsStyling: false,
            });
            swalWithBootstrapButtons4
                .fire({
                    title: "Da li ste sigurni da želite da parking mesto \"" + this.parkingSpace.number + "\"?",
                    text: ``,
                    confirmButtonText: "Da, obriši!",
                    cancelButtonText: "NE",
                    showCancelButton: true,
                })
                .then((result) => {
                    if (result.value) {
                        this.deleteParkingSpace()
                        // const swalWithBootstrapButtons5 = swal.mixin({
                        //     customClass: {
                        //     confirmButton: "btn btn-success btn-fill",
                        //     },
                        //     buttonsStyling: false,
                        // });
                        // swalWithBootstrapButtons5.fire({
                        //     title: "Deleted!",
                        //     text: "Your file has been deleted.",
                        // });
                    }
                });
        }
    }
};
</script>
<style lang="scss">
/* Pozadina modala */
.custom-modal {
  background-color: rgba(0, 0, 0, 0.8);
}

.custom-modal-content {
  background-color: #3b3b5c; /* Tamna pozadina */
  color: #ffffff; /* Svetli tekst */
  border-radius: 8px;
  max-width: 800px;
  width: 800px;
}

/* Stil za telo modala */
.custom-modal-body {
  padding: 20px;
}

/* Stil za tabelu unutar modala */
.custom-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #2c2c3e; /* Tamna pozadina tabele */
  color: rgba(255, 255, 255, 0.87); /* Svetlo sivi tekst */
}

.custom-table th {
  background-color: #3c3c4f; /* Tamna pozadina za zaglavlje */
  color: #ffffff; /* Beli tekst */
  padding: 10px;
  text-align: left;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.custom-table td {
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.87); /* Svetlo sivi tekst */
}

.custom-table tr:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Istaknuta boja za hover */
}


.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Visina loadera */
  background-color: rgba(255, 255, 255, 0.7); /* Providna pozadina */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999; /* Iznad ostalog sadržaja */
}

.loader {
  border: 8px solid #f3f3f3; /* Svetla boja */
  border-top: 8px solid #3498db; /* Boja animacije */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


</style>
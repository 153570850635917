<template>
    <div class="row">
        <!-- Big Chart -->
        <!-- <div class="col-12">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
                <h5 class="card-category"></h5>
                <h2 class="card-title">Poslednjih 7 dana</h2>
              </div>
              <div class="col-sm-6 d-flex d-sm-block">
                <div class="btn-group btn-group-toggle" :class="isRTL ? 'float-left' : 'float-right'"
                  data-toggle="buttons">
                  <label v-for="(option, index) in bigLineChartCategories" :key="option.name"
                    class="btn btn-sm btn-primary btn-simple" :class="{ active: bigLineChart.activeIndex === index }"
                    :id="index">
                    <input type="radio" @click="initBigChart(index)" name="options" autocomplete="off"
                      :checked="bigLineChart.activeIndex === index" />
                    <span class="d-none d-sm-block">{{ option.name }}</span>
                    <span class="d-block d-sm-none">
                      <i :class="option.icon"></i>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </template>
<div class="chart-area">
    <line-chart style="height: 100%" ref="bigChart" :chart-data="bigLineChart.chartData"
        :gradient-colors="bigLineChart.gradientColors" :gradient-stops="bigLineChart.gradientStops"
        :extra-options="bigLineChart.extraOptions">
    </line-chart>
</div>
</card>
</div> -->
        <!-- Stats Cards -->
        <!-- <div class="col-lg-3 col-md-6" v-for="card in statsCards" :key="card.title">
        <stats-card :title="card.title" :sub-title="card.subTitle" :type="card.type" :icon="card.icon">
          <div slot="footer" v-html="card.footer"></div>
        </stats-card>
      </div> -->


        <div class="col-12">
            <card>
                <l-map style="height: 500px; width: 100%;" :zoom="zoom" :center="center">
                    <l-tile-layer :url="mapUrl" :attribution="attribution" />
                    <l-marker v-for="(vehicle, index) in vehicles" :key="index" :lat-lng="vehicle.position">
                        <l-popup>
                            {{ vehicle.name }}<br>
                            <template v-if="vehicle.speed > 0">
                                Brzina: {{ vehicle.speed }} km/h<br>
                            </template>
                            <template v-if="vehicle.updatedLong > 0">
                                {{ (new Date(vehicle.updatedLong)).toLocaleString("de-DE") }}
                            </template>
                        </l-popup>
                    </l-marker>
                </l-map>
            </card>
        </div>
        <div class="col-12">
            <card>
                <el-table :data="checkedPlates" v-loading="ticketsLoading">
                        <el-table-column min-width="130" prop="stamp" label="Datum">
                            <template slot-scope="scope">
                                <span>{{ (new Date(scope.row.stampLong)).toLocaleString("de-DE") }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="80" prop="client" label="Zona">
                            <template slot-scope="scope">
                                <span>{{ scope.row.client?.name }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="80" prop="plates" label="Tablice">
                            <template slot-scope="scope">
                                <span>{{ scope.row.plates }}</span>
                            </template>
                        </el-table-column>
                        
                    </el-table>
            </card>
        </div>
    </div>
</template>
<script>
import LineChart from '@/components/Charts/LineChart';
import BarChart from '@/components/Charts/BarChart';
import * as chartConfigs from '@/components/Charts/config';
import TaskList from './TaskList';
import UserTable from './UserTable';
import CountryMapCard from './CountryMapCard';
import StatsCard from 'src/components/Cards/StatsCard';
import config from '@/config';
import axios from 'axios';
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import 'leaflet/dist/leaflet.css';
import { Table, TableColumn, TimeSelect, DatePicker, Select, Option } from 'element-ui';

import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// let bigChartData = [
//   [100, 70, 90, 70, 85, 60, 75],
//   [80, 120, 105, 110, 95, 105, 90],
//   [60, 80, 65, 130, 80, 105, 90]
// ]
// let bigChartLabels = ['PON', 'UTO', 'SRE', 'ČET', 'PET', 'SUB', 'NED']
// let bigChartDatasetOptions = {
//   fill: true,
//   borderColor: config.colors.primary,
//   borderWidth: 2,
//   borderDash: [],
//   borderDashOffset: 0.0,
//   pointBackgroundColor: config.colors.primary,
//   pointBorderColor: 'rgba(255,255,255,0)',
//   pointHoverBackgroundColor: config.colors.primary,
//   pointBorderWidth: 20,
//   pointHoverRadius: 4,
//   pointHoverBorderWidth: 15,
//   pointRadius: 4,
// }

export default {
    components: {
        LineChart,
        BarChart,
        StatsCard,
        TaskList,
        CountryMapCard,
        UserTable,
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    },
    data() {
        return {
            customIcon: L.divIcon({
                className: "icon-delivery-fast", // CSS klasa za prilagođeni stil ikone
                iconSize: [30, 30], // Dimenzije (ako je potrebno)
            }),
            checkedPlates: [],
            center: [42.4533932, 18.5291398], // Koordinate (lat, lng)
            vehiclePosition: [42.4533932, 18.5291398], // Koordinate (lat, lng)
            vehicles: [],
            ticketsLoading: false,
            zoom: 15,
            mapUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            attribution:
                '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',

            url: 'https://hercegnovi.scancar.org:8443/kuca',
            bigChartLabels: ['PON', 'UTO', 'SRE', 'ČET', 'PET', 'SUB', 'NED'],
            bigChartDatasetOptions: {
                fill: true,
                borderColor: config.colors.primary,
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: config.colors.primary,
                pointBorderColor: 'rgba(255,255,255,0)',
                pointHoverBackgroundColor: config.colors.primary,
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
            },
            bigChartData: [[], [], [], []], // Prazni nizovi
            bigLineChart: {
                activeIndex: 0,
                chartData: {
                    datasets: [
                        {
                            ...this.bigChartDatasetOptions,
                            data: [] // Prazan niz podataka
                        }
                    ],
                    labels: ['PON', 'UTO', 'SRE', 'ČET', 'PET', 'SUB', 'NED']
                },
                extraOptions: chartConfigs.purpleChartOptions,
                gradientColors: config.colors.primaryGradient,
                gradientStops: [1, 0.4, 0],
            },
            statsCards: [
                {
                    title: '0',
                    subTitle: 'Doplatne karte',
                    type: 'warning',
                    icon: 'tim-icons icon-badge',
                    footer: '<i class="tim-icons icon-refresh-01"></i> Update Now'
                },
                {
                    title: '0',
                    subTitle: 'SMS',
                    type: 'primary',
                    icon: 'tim-icons icon-chat-33',
                    footer: '<i class="tim-icons icon-sound-wave"></i></i> Last Research'
                },
                {
                    title: '0',
                    subTitle: 'Skeniranja',
                    type: 'info',
                    icon: 'tim-icons icon-video-66',
                    footer: '<i class="tim-icons icon-trophy"></i> Customer feedback'
                },
                {
                    title: '1',
                    subTitle: 'Kiosk karte',
                    type: 'danger',
                    icon: 'tim-icons icon-badge',
                    footer: '<i class="tim-icons icon-watch-time"></i> In the last hours'
                }
            ],
            purpleLineChart: {
                extraOptions: chartConfigs.purpleChartOptions,
                chartData: {
                    labels: ['JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
                    datasets: [
                        {
                            label: 'Data',
                            fill: true,
                            borderColor: config.colors.primary,
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: config.colors.primary,
                            pointBorderColor: 'rgba(255,255,255,0)',
                            pointHoverBackgroundColor: config.colors.primary,
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            pointRadius: 4,
                            data: [80, 100, 70, 80, 120, 80]
                        }
                    ]
                },
                gradientColors: config.colors.primaryGradient,
                gradientStops: [1, 0.2, 0]
            },
            greenLineChart: {
                extraOptions: chartConfigs.greenChartOptions,
                chartData: {
                    labels: ['JUL', 'AUG', 'SEP', 'OCT', 'NOV'],
                    datasets: [
                        {
                            label: 'My First dataset',
                            fill: true,
                            borderColor: config.colors.danger,
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: config.colors.danger,
                            pointBorderColor: 'rgba(255,255,255,0)',
                            pointHoverBackgroundColor: config.colors.danger,
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            pointRadius: 4,
                            data: [90, 27, 60, 12, 80]
                        }
                    ]
                },
                gradientColors: [
                    'rgba(66,134,121,0.15)',
                    'rgba(66,134,121,0.0)',
                    'rgba(66,134,121,0)'
                ],
                gradientStops: [1, 0.4, 0]
            },
            blueBarChart: {
                extraOptions: chartConfigs.barChartOptions,
                chartData: {
                    labels: ['USA', 'GER', 'AUS', 'UK', 'RO', 'BR'],
                    datasets: [
                        {
                            label: 'Countries',
                            fill: true,
                            borderColor: config.colors.info,
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            data: [53, 20, 10, 80, 100, 45]
                        }
                    ]
                },
                gradientColors: config.colors.primaryGradient,
                gradientStops: [1, 0.4, 0],
                intervalId: null,
                currentChartIndex: 0
            }
        };
    },
    computed: {
        enableRTL() {
            return this.$route.query.enableRTL;
        },
        isRTL() {
            return this.$rtl.isRTL;
        },
        bigLineChartCategories() {
            return [{ name: 'SMS', icon: 'tim-icons icon-single-02' }, { name: 'Doplatne karte', icon: 'tim-icons icon-gift-2' }, { name: 'Skeniranja', icon: 'tim-icons icon-tap-02' }, { name: 'Kiosk karte', icon: 'tim-icons icon-tap-02' }];
        }
    },
    methods: {
        initBigChart(index) {
            console.log("Chart index: " + index);
            this.currentChartIndex = index
            let chartData = {
                datasets: [{
                    ...this.bigChartDatasetOptions,
                    data: this.bigChartData[index]
                }],
                labels: this.bigChartLabels
            };
            this.$refs.bigChart.updateGradients(chartData);
            this.bigLineChart.chartData = chartData;
            this.bigLineChart.activeIndex = index;
        },
        getWeekAnalytic() {
            axios.get(this.url + '/reports/getWeekAnalytic').then(result => {
                this.loading = false

                if (result.data.result === 'ok') {

                    this.bigChartData[1] = result.data.tickets
                    this.bigChartData[2] = result.data.checkedPlates
                    this.bigChartData[0] = result.data.sms
                    this.bigChartData[3] = result.data.kioskTickets
                    this.bigChartLabels = result.data.dates
                    this.bigLineChart.chartData.datasets[0].data = this.bigChartData[0];
                    console.log(this.bigChartData);
                    if (!this.currentChartIndex) {
                        this.currentChartIndex = 0
                    }
                    this.initBigChart(this.currentChartIndex)

                    this.statsCards[0].title = result.data.todayTickets.toString()
                    this.statsCards[1].title = result.data.todaySms.toString()
                    this.statsCards[2].title = result.data.todayCheckedPlates.toString()
                    this.statsCards[3].title = result.data.todayKioskTickets.toString()

                    this.statsCards[0].footer = result.data.time
                    this.statsCards[1].footer = result.data.time
                    this.statsCards[2].footer = result.data.time
                    this.statsCards[3].footer = result.data.time
                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        getScanCars() {
            axios.get(this.url + '/reports/getScanCars').then(result => {
                this.loading = false

                if (result.data.result === 'ok') {
                    this.vehicles = []; // Reset lista vozila
                    for (let i = 0; i < result.data.scanCars.length; i++) {
                        const vehicle = {
                            position: { // Objekat sa lat i lng
                                lat: result.data.scanCars[i].lat,
                                lng: result.data.scanCars[i].lon
                            },
                            speed: result.data.scanCars[i].roundedSpeed,
                            name: result.data.scanCars[i].name + " " + result.data.scanCars[i].lastName,
                            updatedLong: result.data.scanCars[i].updatedLong

                        };
                        this.vehicles.push(vehicle);
                    }

                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        getCheckedPlates() {
            axios.get(this.url + '/scanDashboard/getLastCheckedPlates').then(result => {
                this.loading = false

                if (result.data.result === 'ok') {
                    this.checkedPlates = result.data.checkedPlates

                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        }
    },
    mounted() {
        this.i18n = this.$i18n;
        if (this.enableRTL) {
            this.i18n.locale = 'ar';
            this.$rtl.enableRTL();
        }
        // this.getWeekAnalytic()
        this.getScanCars()
        this.getCheckedPlates()
        this.intervalId = setInterval(() => {
            this.getScanCars();
            // this.getWeekAnalytic();
        }, 10000);
        // this.initBigChart(0);
    },
    beforeDestroy() {
        if (this.$rtl.isRTL) {
            this.i18n.locale = 'en';
            this.$rtl.disableRTL();
            if (this.intervalId) {
                clearInterval(this.intervalId);
            }
        }
    }
};
</script>
<style>
.leaflet-container {
    height: 100%;
    width: 100%;
}
</style>
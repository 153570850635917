<template>
    <div>
        <div v-if="loading" class="loader-container">
            <div class="loader"></div> <!-- Stilizovani loader -->
        </div>
        <div class="row">
            <div class="col-md-24">
                <card>
                    <template slot="header">
                        <h5 class="card-category"></h5>
                        <h3 class="card-title">Vlasnici vozila</h3>
                    </template>


                    <div class="row">
                        <div class="col-md-3">
                            <card>
                                <h4 slot="header" class="card-title"> </h4>
                                <base-button class="mt-3" native-type="submit" type="primary"
                                    @click="plateOwner = { }; editPlateOwner = true">Dodaj novog vlasnika</base-button>
                            </card>
                        </div>
                    </div>
                    <el-table :data="plateOwners" v-loading="ticketsLoading">
                        <el-table-column min-width="80" prop="plates" label="Tablice">
                            <template slot-scope="scope">
                                <span>{{ scope.row.plates }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="80" prop="name" label="Ime">
                            <template slot-scope="scope">
                                <span>{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="80" prop="lastName" label="Prezime">
                            <template slot-scope="scope">
                                <span>{{ scope.row.lastName }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="80" prop="country" label="Država">
                            <template slot-scope="scope">
                                <span>{{ scope.row.country }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="80" prop="city" label="Grad">
                            <template slot-scope="scope">
                                <span>{{ scope.row.city }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="80" prop="address" label="Adresa">
                            <template slot-scope="scope">
                                <span>{{ scope.row.address }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="80" prop="postNumber" label="Poštanski broj">
                            <template slot-scope="scope">
                                <span>{{ scope.row.postNumber }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="80" prop="stampLong" label="Datum">
                            <template slot-scope="scope">
                                <span>{{ (new Date(scope.row.stampLong)).toLocaleDateString("de-DE", {
                                        year: "numeric",
                                        month: "2-digit", day: "2-digit"
                                    }) }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="100" header-align="right" align="right" label="Actions">
                            <div slot-scope="scope" class="text-right table-actions">

                                <base-button :type="'success'" class="btn-link"
                                    @click="plateOwner = scope.row; editPlateOwner = true">
                                    Izmeni
                                </base-button>

                                <base-button :type="'danger'" class="btn-link"
                                    @click="plateOwner = scope.row; deletePlateOwnerPrompt()">
                                    Obriši
                                </base-button>

                            </div>
                        </el-table-column>
                    </el-table>
                </card>
            </div>


        </div>
        <modal :show.sync="editPlateOwner" :show-close="true" :scrollToBottom="true" :appendToBody="true"
            modalClasses="custom-modal" modalContentClasses="custom-modal-content" bodyClasses="custom-modal-body">
            <base-button :type="'danger'" icon size="sm" class="btn-link"
                @click="editPlateOwner = false; plateOwner = {}">
                <i class="tim-icons icon-simple-remove"></i>
            </base-button>


            <card class="stacked-form" title="Vlasnik">
                <form @submit.prevent>
                            <div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <base-input label="Ime" type="text" v-model="plateOwner.name"
                                            placeholder="Ime">
                                        </base-input>
                                    </div>
                                    <div class="col-md-4">
                                        <base-input label="Prezime" type="text" v-model="plateOwner.lastName"
                                            placeholder="Prezime">
                                        </base-input>
                                    </div>
                                    <div class="col-md-4">
                                        <base-input label="Država" type="text" v-model="plateOwner.country"
                                            placeholder="Država">
                                        </base-input>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <base-input label="Grad" type="text" v-model="plateOwner.city"
                                            placeholder="Grad">
                                        </base-input>
                                    </div>
                                    <div class="col-md-4">
                                        <base-input label="Adresa" type="text" v-model="plateOwner.address"
                                            placeholder="Adresa">
                                        </base-input>
                                    </div>
                                    <div class="col-md-4">
                                        <base-input label="Poštanski broj" type="text" v-model="plateOwner.postNumber"
                                            placeholder="Poštanski broj">
                                        </base-input>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <base-input label="Tablice" type="text" v-model="plateOwner.plates"
                                            placeholder="Tablice">
                                        </base-input>
                                    </div>
                                    <div class="col-md-4">
                                        <base-input label="Datum dobijanja podataka">
                                            <el-date-picker type="date" placeholder="Datum dobijanja podataka" value-format="timestamp"
                                                v-model="plateOwner.stampLong">
                                            </el-date-picker>
                                        </base-input>
                                    </div>
                                    <div class="col-md-4">
                                        
                                    </div>
                                </div>

                            </div>
                        </form>
                       
                <base-button class="mt-3" native-type="submit" style="margin-left: 20px;" type="primary"
                    @click="savePlateOwner()">Sačuvaj</base-button>
                <base-button class="mt-3" native-type="submit" style="margin-left: 20px;" type="danger"
                    @click="editPlateOwner = false; plateOwner = {}">Odustani</base-button>
            </card>
                    
        </modal>

        
    </div>
</template>
<script>
import { TabPane, Tabs, Collapse, CollapseItem } from 'src/components';
import { Table, TableColumn, TimeSelect, DatePicker, Select, Option } from 'element-ui';
import { BaseCheckbox, BaseProgress, RouteBreadCrumb } from 'src/components';
import axios from 'axios'
import swal from 'sweetalert2';
import { BaseNav, Modal } from '@/components';
import htmlDocx from 'html-docx-js';
import { saveAs } from 'file-saver';


export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [DatePicker.name]: DatePicker,
        [TimeSelect.name]: TimeSelect,
        [Option.name]: Option,
        [Select.name]: Select,
        TabPane,
        Tabs,
        Collapse,
        CollapseItem,
        Modal
    },
    mounted() {
        this.getPlateOwners()
        // this.getClients()
    },
    data() {
        const now = new Date();

        // Postavljanje startStamp na 00:00:00 danas
        const startStamp = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0).getTime();

        // Postavljanje endStamp na 23:59:59 danas
        const endStamp = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59).getTime();

        return {
            url: 'https://hercegnovi.scancar.org:8443/kuca',
            ticketsLoading: false,
            loading: false,
            plateOwner: {},
            editPlateOwner: false
        };
    },
    methods: {
        getPlateOwners() {
            this.ticketsLoading = true
            axios.get(this.url + '/settings/getPlateOwners').then(result => {
                this.ticketsLoading = false

                if (result.data.result === 'ok') {
                    this.plateOwners = result.data.plateOwners
                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        savePlateOwner() {
            this.loading = true
            axios.post(this.url + '/settings/savePlateOwner', this.plateOwner).then(result => {
                this.loading = false

                if (result.data.result === 'ok') {
                    this.$notify({
                        message:
                            'Sacuvano',
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'success'
                    });
                    this.editPlateOwner = false
                    this.plateOwner = {}
                    this.getPlateOwners()
                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        deletePlateOwner() {
            this.loading = true
            axios.post(this.url + '/settings/deletePlateOwner', this.plateOwner).then(result => {
                this.loading = false

                if (result.data.result === 'ok') {
                    this.$notify({
                        message:
                            'Obrisano',
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'success'
                    });
                    this.getPlateOwners()
                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        deletePlateOwnerPrompt() {
            const swalWithBootstrapButtons4 = swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success btn-fill",
                    cancelButton: "btn btn-danger btn-fill",
                },
                buttonsStyling: false,
            });
            swalWithBootstrapButtons4
                .fire({
                    title: "Da li ste sigurni da želite da obrišete vlasnika \"" + this.plateOwner.name + " "+ this.plateOwner.lastName+"\"?",
                    text: ``,
                    confirmButtonText: "Da, obriši!",
                    cancelButtonText: "NE",
                    showCancelButton: true,
                })
                .then((result) => {
                    if (result.value) {
                        this.deletePlateOwner()
                        // const swalWithBootstrapButtons5 = swal.mixin({
                        //     customClass: {
                        //     confirmButton: "btn btn-success btn-fill",
                        //     },
                        //     buttonsStyling: false,
                        // });
                        // swalWithBootstrapButtons5.fire({
                        //     title: "Deleted!",
                        //     text: "Your file has been deleted.",
                        // });
                    }
                });
        }

    }
};
</script>
<style lang="scss">
/* Pozadina modala */
.custom-modal {
    background-color: rgba(0, 0, 0, 0.8);
}

.custom-modal-content {
    background-color: #3b3b5c;
    /* Tamna pozadina */
    color: #ffffff;
    /* Svetli tekst */
    border-radius: 8px;
    max-width: 800px;
    width: 800px;
}

/* Stil za telo modala */
.custom-modal-body {
    padding: 20px;
}

/* Stil za tabelu unutar modala */
.custom-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #2c2c3e;
    /* Tamna pozadina tabele */
    color: rgba(255, 255, 255, 0.87);
    /* Svetlo sivi tekst */
}

.custom-table th {
    background-color: #3c3c4f;
    /* Tamna pozadina za zaglavlje */
    color: #ffffff;
    /* Beli tekst */
    padding: 10px;
    text-align: left;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.custom-table td {
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.87);
    /* Svetlo sivi tekst */
}

.custom-table tr:hover {
    background-color: rgba(255, 255, 255, 0.1);
    /* Istaknuta boja za hover */
}


.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Visina loadera */
    background-color: rgba(255, 255, 255, 0.7);
    /* Providna pozadina */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    /* Iznad ostalog sadržaja */
}

.loader {
    border: 8px solid #f3f3f3;
    /* Svetla boja */
    border-top: 8px solid #3498db;
    /* Boja animacije */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
<template>
    <div>
        <div v-if="loading" class="loader-container">
            <div class="loader"></div> <!-- Stilizovani loader -->
        </div>
        <div class="row">
            <div class="col-md-24">
                <card>
                    <template slot="header">
                        <h5 class="card-category"></h5>
                        <h3 class="card-title">Rent-a-car</h3>
                    </template>


                    <div class="row">
                        <div class="col-md-3">
                            <card>
                                <h4 slot="header" class="card-title"> </h4>
                                <base-button class="mt-3" native-type="submit" type="primary"
                                    @click="car = {}; editCar = true">Dodaj
                                    novi</base-button>
                            </card>
                        </div>
                    </div>
                    <el-table :data="rentCars" v-loading="ticketsLoading">
                        <el-table-column min-width="80" prop="name" label="Ime">
                            <template slot-scope="scope">
                                <span>{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="80" prop="email" label="E-Mail">
                            <template slot-scope="scope">
                                <span>{{ scope.row.email }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="100" header-align="right" align="right" label="Actions">
                            <div slot-scope="scope" class="text-right table-actions">

                                <base-button :type="'success'" class="btn-link"
                                    @click="car = scope.row; editCar = true">
                                    Izmeni
                                </base-button>

                                <base-button :type="'success'" class="btn-link"
                                    @click="car = scope.row; getRentPlates()">
                                    Vozila
                                </base-button>

                                <base-button :type="'danger'" class="btn-link"
                                    @click="car = scope.row; deleteCarPrompt()">
                                    Obriši
                                </base-button>

                            </div>
                        </el-table-column>
                    </el-table>
                </card>
            </div>


        </div>
        <modal :show.sync="editCar" :show-close="true" :scrollToBottom="true" :appendToBody="true"
            modalClasses="custom-modal" modalContentClasses="custom-modal-content" bodyClasses="custom-modal-body">
            <base-button :type="'danger'" icon size="sm" class="btn-link" @click="editCar = false; car = {}">
                <i class="tim-icons icon-simple-remove"></i>
            </base-button>


            <card class="stacked-form" title="">
                <form @submit.prevent>
                    <div>
                        <div class="row">
                            <div class="col-md-12">
                                <base-input label="Ime" type="text" v-model="car.name" placeholder="Ime">
                                </base-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <base-input label="E-Mail" type="text" v-model="car.email" placeholder="E-Mail">
                                </base-input>
                            </div>
                        </div>

                    </div>
                </form>

                <base-button class="mt-3" native-type="submit" style="margin-left: 20px;" type="primary"
                    @click="saveCar()">Sačuvaj</base-button>
                <base-button class="mt-3" native-type="submit" style="margin-left: 20px;" type="danger"
                    @click="editCar = false; car = {}">Odustani</base-button>
            </card>

        </modal>

        <modal :show.sync="showPlates" :show-close="true" :scrollToBottom="true" :appendToBody="true"
            modalClasses="custom-modal" modalContentClasses="custom-modal-content" bodyClasses="custom-modal-body">
            <base-button :type="'danger'" icon size="sm" class="btn-link" @click="showPlates = false;">
                <i class="tim-icons icon-simple-remove"></i>
            </base-button>

            <card class="stacked-form" title="">
                <h4>{{ car.name }}</h4>
                <base-button class="mt-3" native-type="submit" style="margin-left: 20px;" type="primary"
                    @click="plate = {}; editPlates = true">Dodaj tablice</base-button>
                <el-table :data="plates" v-loading="ticketsLoading">
                    <el-table-column min-width="80" prop="plates" label="Tablice">
                        <template slot-scope="scope">
                            <span>{{ scope.row.plates }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column min-width="100" header-align="right" align="right" label="Actions">
                        <div slot-scope="scope" class="text-right table-actions">

                            <base-button :type="'success'" class="btn-link"
                                @click="plate = scope.row; editPlates = true">
                                Izmeni
                            </base-button>

                            <base-button :type="'danger'" class="btn-link"
                                @click="plate = scope.row; deletePlatesPrompt()">
                                Obriši
                            </base-button>

                        </div>
                    </el-table-column>
                </el-table>
            </card>

        </modal>

        <modal :show.sync="editPlates" :show-close="true" :scrollToBottom="true" :appendToBody="true"
            modalClasses="custom-modal" modalContentClasses="custom-modal-content" bodyClasses="custom-modal-body">
            <base-button :type="'danger'" icon size="sm" class="btn-link" @click="editPlates = false; plate = {}">
                <i class="tim-icons icon-simple-remove"></i>
            </base-button>


            <card class="stacked-form" title="">
                <form @submit.prevent>
                    <div>
                        <div class="row">
                            <div class="col-md-12">
                                <base-input label="Tablice" type="text" v-model="plate.plates" placeholder="Tablice">
                                </base-input>
                            </div>
                        </div>

                    </div>
                </form>

                <base-button class="mt-3" native-type="submit" style="margin-left: 20px;" type="primary"
                    @click="savePlates()">Sačuvaj</base-button>
                <base-button class="mt-3" native-type="submit" style="margin-left: 20px;" type="danger"
                    @click="editPlates = false; plate = {}">Odustani</base-button>
            </card>

        </modal>
    </div>
</template>
<script>
import { TabPane, Tabs, Collapse, CollapseItem } from 'src/components';
import { Table, TableColumn, TimeSelect, DatePicker, Select, Option } from 'element-ui';
import { BaseCheckbox, BaseProgress, RouteBreadCrumb } from 'src/components';
import axios from 'axios'
import swal from 'sweetalert2';
import { BaseNav, Modal } from '@/components';
import htmlDocx from 'html-docx-js';
import { saveAs } from 'file-saver';


export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [DatePicker.name]: DatePicker,
        [TimeSelect.name]: TimeSelect,
        [Option.name]: Option,
        [Select.name]: Select,
        TabPane,
        Tabs,
        Collapse,
        CollapseItem,
        Modal
    },
    mounted() {
        this.getRentCars()
        // this.getClients()
    },
    data() {
        const now = new Date();

        // Postavljanje startStamp na 00:00:00 danas
        const startStamp = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0).getTime();

        // Postavljanje endStamp na 23:59:59 danas
        const endStamp = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59).getTime();

        return {
            url: 'https://hercegnovi.scancar.org:8443/kuca',
            ticketsLoading: false,
            loading: false,
            showPlates: false,
            whitelistCategories: [],
            car: {},
            editCar: false,
            editPlates: false,
            plate: {}
        };
    },
    methods: {
        getRentCars() {
            this.ticketsLoading = true
            axios.get(this.url + '/whitelist/getRentCars',).then(result => {
                this.ticketsLoading = false

                if (result.data.result === 'ok') {
                    this.rentCars = result.data.rentCars
                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        getRentPlates() {
            this.loading = true
            axios.get(this.url + '/whitelist/getRentPlates?rentCarId=' + this.car.id).then(result => {
                this.loading = false

                if (result.data.result === 'ok') {
                    this.plates = result.data.plates
                    this.showPlates = true
                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        saveCar() {
            this.loading = true
            axios.post(this.url + '/whitelist/saveRentCar', this.car).then(result => {
                this.loading = false

                if (result.data.result === 'ok') {
                    this.$notify({
                        message:
                            'Sacuvano',
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'success'
                    });
                    this.editCar = false
                    this.car = {}
                    this.getRentCars()
                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        savePlates() {
            this.loading = true
            this.plate.rentCarId = this.car.id
            axios.post(this.url + '/whitelist/saveRentPlates', this.plate).then(result => {
                this.loading = false

                if (result.data.result === 'ok') {
                    this.$notify({
                        message:
                            'Sacuvano',
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'success'
                    });
                    this.editPlates = false
                    this.plate = {}
                    this.getRentPlates()
                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        deleteCar() {
            this.loading = true
            axios.post(this.url + '/whitelist/deleteRentCar', this.car).then(result => {
                this.loading = false

                if (result.data.result === 'ok') {
                    this.$notify({
                        message:
                            'Obrisano',
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'success'
                    });
                    this.getRentCars()
                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        deletePlates() {
            this.loading = true
            axios.post(this.url + '/whitelist/deleteRentPlates', this.plate).then(result => {
                this.loading = false

                if (result.data.result === 'ok') {
                    this.$notify({
                        message:
                            'Obrisano',
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'success'
                    });
                    this.getRentPlates()
                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        deleteCarPrompt() {
            const swalWithBootstrapButtons4 = swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success btn-fill",
                    cancelButton: "btn btn-danger btn-fill",
                },
                buttonsStyling: false,
            });
            swalWithBootstrapButtons4
                .fire({
                    title: "Da li ste sigurni da želite da obrišete rent-a-car \"" + this.car.name + "\"?",
                    text: ``,
                    confirmButtonText: "Da, obriši!",
                    cancelButtonText: "NE",
                    showCancelButton: true,
                })
                .then((result) => {
                    if (result.value) {
                        this.deleteCar()
                        // const swalWithBootstrapButtons5 = swal.mixin({
                        //     customClass: {
                        //     confirmButton: "btn btn-success btn-fill",
                        //     },
                        //     buttonsStyling: false,
                        // });
                        // swalWithBootstrapButtons5.fire({
                        //     title: "Deleted!",
                        //     text: "Your file has been deleted.",
                        // });
                    }
                });
        },
        deletePlatesPrompt() {
            const swalWithBootstrapButtons4 = swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success btn-fill",
                    cancelButton: "btn btn-danger btn-fill",
                },
                buttonsStyling: false,
            });
            swalWithBootstrapButtons4
                .fire({
                    title: "Da li ste sigurni da želite da obrišete tablice \"" + this.plate.plates + "\"?",
                    text: ``,
                    confirmButtonText: "Da, obriši!",
                    cancelButtonText: "NE",
                    showCancelButton: true,
                })
                .then((result) => {
                    if (result.value) {
                        this.deletePlates()
                        // const swalWithBootstrapButtons5 = swal.mixin({
                        //     customClass: {
                        //     confirmButton: "btn btn-success btn-fill",
                        //     },
                        //     buttonsStyling: false,
                        // });
                        // swalWithBootstrapButtons5.fire({
                        //     title: "Deleted!",
                        //     text: "Your file has been deleted.",
                        // });
                    }
                });
        }
    }
};
</script>
<style lang="scss">
/* Pozadina modala */
.custom-modal {
    background-color: rgba(0, 0, 0, 0.8);
}

.custom-modal-content {
    background-color: #3b3b5c;
    /* Tamna pozadina */
    color: #ffffff;
    /* Svetli tekst */
    border-radius: 8px;
    max-width: 800px;
    width: 800px;
}

/* Stil za telo modala */
.custom-modal-body {
    padding: 20px;
}

/* Stil za tabelu unutar modala */
.custom-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #2c2c3e;
    /* Tamna pozadina tabele */
    color: rgba(255, 255, 255, 0.87);
    /* Svetlo sivi tekst */
}

.custom-table th {
    background-color: #3c3c4f;
    /* Tamna pozadina za zaglavlje */
    color: #ffffff;
    /* Beli tekst */
    padding: 10px;
    text-align: left;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.custom-table td {
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.87);
    /* Svetlo sivi tekst */
}

.custom-table tr:hover {
    background-color: rgba(255, 255, 255, 0.1);
    /* Istaknuta boja za hover */
}


.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Visina loadera */
    background-color: rgba(255, 255, 255, 0.7);
    /* Providna pozadina */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    /* Iznad ostalog sadržaja */
}

.loader {
    border: 8px solid #f3f3f3;
    /* Svetla boja */
    border-top: 8px solid #3498db;
    /* Boja animacije */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
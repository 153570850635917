<template>
  <div>
    <div class="row">
      <!-- lock table -->
      <div class="col-md-6">
        <card>
          <template slot="header">
            <h5 class="card-category"></h5>
            <h3 class="card-title">Brave</h3>
          </template>
          <base-button
            class="mt-3"
            native-type="submit"
            type="primary"
            @click="handleLockAdd"
            >Nova brava</base-button
          >

          <lock-table
            :lockSubmited="lockSubmited"
            @edit="handleLockEdit($event)"
            @submitedfinish="lockSubmited = false"
          ></lock-table>
        </card>
      </div>

      <!-- service table -->
      <div class="col-md-6">
        <card>
          <template slot="header">
            <h5 class="card-category"></h5>
            <h3 class="card-title">Servisi</h3>
          </template>
          <base-button
            class="mt-3"
            native-type="submit"
            type="primary"
            @click="handleServiceAdd"
            >Novi servis</base-button
          >

          <service-table
            :serviceSubmited="serviceSubmited"
            @edit="handleServiceEdit($event)"
            @submitedfinish="serviceSubmited = false"
          ></service-table>
        </card>
      </div>

      <!-- card table -->
      <div class="col-md-6">
        <card>
          <template slot="header">
            <h5 class="card-category"></h5>
            <h3 class="card-title">Karte</h3>
          </template>
          <base-button
            class="mt-3"
            native-type="submit"
            type="primary"
            @click="handleCardAdd"
            >Nova karta</base-button
          >

          <card-table
            :cardSubmited="cardSubmited"
            @edit="handleCardEdit($event)"
            @submitedfinish="cardSubmited = false"
          ></card-table>
        </card>
      </div>

      <!-- parcel table -->
      <div class="col-md-6">
        <card>
          <template slot="header">
            <h5 class="card-category"></h5>
            <h3 class="card-title">Paketomati</h3>
          </template>
          <base-button
            class="mt-3"
            native-type="submit"
            type="primary"
            @click="handleParcelAdd"
            >Novi paketomat</base-button
          >

          <parcel-table
            :parcelSubmited="parcelSubmited"
            @edit="handleParcelEdit($event)"
            @submitedfinish="parcelSubmited = false"
          ></parcel-table>
        </card>
      </div>

      <!-- channel table -->
      <div class="col-md-6">
        <card>
          <template slot="header">
            <h5 class="card-category"></h5>
            <h3 class="card-title">Kanali</h3>
          </template>
          <base-button
            class="mt-3"
            native-type="submit"
            type="primary"
            @click="handleChannelAdd"
            >Novi kanal</base-button
          >

          <channel-table
            :channelSubmited="channelSubmited"
            @edit="handleChannelEdit($event)"
            @submitedfinish="channelSubmited = false"
          ></channel-table>
        </card>
      </div>

      <!-- box table -->
      <div class="col-md-6">
        <card>
          <template slot="header">
            <h5 class="card-category"></h5>
            <h3 class="card-title">Boksevi</h3>
          </template>
          <base-button
            class="mt-3"
            native-type="submit"
            type="primary"
            @click="handleBoxAdd"
            >Novi boks</base-button
          >

          <box-table
            :boxSubmited="boxSubmited"
            @edit="handleBoxEdit($event)"
            @submitedfinish="boxSubmited = false"
          ></box-table>
        </card>
      </div>

      <!-- action table -->
      <!-- <div class="col-md-6">
        <card>
          <template slot="header">
            <h5 class="card-category"></h5>
            <h3 class="card-title">Akcije</h3>
          </template>
          <base-button
            class="mt-3"
            native-type="submit"
            type="primary"
            @click="handleActionAdd"
            >Nova akcija</base-button
          >

          <action-table
            :actionSubmited="actionSubmited"
            @edit="handleActionEdit($event)"
            @submitedfinish="actionSubmited = false"
          ></action-table>
        </card>
      </div> -->

      <!-- hotel table -->
      <div class="col-md-6">
        <card>
          <template slot="header">
            <h5 class="card-category"></h5>
            <h3 class="card-title">Hoteli</h3>
          </template>
          <base-button
            class="mt-3"
            native-type="submit"
            type="primary"
            @click="handleHotelAdd"
            >Novi hotel</base-button
          >

          <hotel-table
            :hotelSubmited="hotelSubmited"
            @edit="handleHotelEdit($event)"
            @submitedfinish="hotelSubmited = false"
          ></hotel-table>
        </card>
      </div>

      <!-- room table -->
      <div class="col-md-6">
        <card>
          <template slot="header">
            <h5 class="card-category"></h5>
            <h3 class="card-title">Sobe</h3>
          </template>
          <base-button
            class="mt-3"
            native-type="submit"
            type="primary"
            @click="handleRoomAdd"
            >Nova soba</base-button
          >

          <room-table
            :roomSubmited="roomSubmited"
            @edit="handleRoomEdit($event)"
            @submitedfinish="roomSubmited = false"
          ></room-table>
        </card>
      </div>

      <!-- type table -->
      <div class="col-md-6">
        <card>
          <template slot="header">
            <h5 class="card-category"></h5>
            <h3 class="card-title">Tip sobe</h3>
          </template>
          <base-button
            class="mt-3"
            native-type="submit"
            type="primary"
            @click="handleTypeAdd"
            >Novi tip</base-button
          >

          <type-table
            :typeSubmited="typeSubmited"
            @edit="handleTypeEdit($event)"
            @submitedfinish="typeSubmited = false"
          ></type-table>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <card>
          <!-- <template slot="header">
                        <h5 class="card-category"></h5>
                        <h3 class="card-title">Kontrolori</h3>
                    </template>
                    <base-button class="mt-3" native-type="submit" type="primary" @click="employee = {}; editEmployeeDialog = true">Novi kontrolor</base-button>
                    <el-table :data="employees" v-loading="employeesLoading">
                        <el-table-column min-width="100" prop="name" label="Ime">
                            <template slot-scope="scope">
                                <span>{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="100" prop="lastName" label="Prezime">
                            <template slot-scope="scope">
                                <span>{{ scope.row.lastName }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="100" prop="userName" label="Prezime">
                            <template slot-scope="scope">
                                <span>{{ scope.row.userName }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="100" prop="password" label="Lozinka">
                            <template slot-scope="scope">
                                <span>{{ scope.row.password }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="100" prop="active" label="Status">
                            <template slot-scope="scope">
                                <span v-if="scope.row.active" style="background-color: green;">Aktivan</span>
                                <span v-else style="background-color: red;">Neaktivan</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="150" header-align="right" align="right" label="Actions">
                            <div slot-scope="scope" class="text-right table-actions">
                                <el-tooltip content="Izmeni" effect="light" :open-delay="300" placement="top">
                                    <base-button :type="'success'" icon size="sm" class="btn-link"
                                        @click="employee = scope.row; editEmployeeDialog = true">
                                        <i class="tim-icons icon-pencil"></i>
                                    </base-button>
                                </el-tooltip>
                                <el-tooltip content="Obriši" effect="light" :open-delay="300" placement="top">
                                    <base-button :type="'danger'" icon size="sm" class="btn-link"
                                        @click="employee = scope.row; deleteEmployeePrompt()">
                                        <i class="tim-icons icon-simple-remove"></i>
                                    </base-button>
                                </el-tooltip>
                            </div>
                        </el-table-column>
                    </el-table> -->
        </card>
      </div>
    </div>

    <lock-dialog
      :isEdit="isLockEdit"
      :lock="lock"
      :modalActive="lockDialog"
      @close="lockDialog = false"
      @submited="(lockDialog = false), (lockSubmited = true)"
    ></lock-dialog>

    <service-dialog
      :isEdit="isServiceEdit"
      :service="service"
      :modalActive="serviceDialog"
      @close="serviceDialog = false"
      @submited="(serviceDialog = false), (serviceSubmited = true)"
    ></service-dialog>

    <card-dialog
      :isEdit="isCardEdit"
      :card="card"
      :modalActive="cardDialog"
      @close="cardDialog = false"
      @submited="(cardDialog = false), (cardSubmited = true)"
    ></card-dialog>

    <parcel-dialog
      :isEdit="isParcelEdit"
      :parcel="parcel"
      :modalActive="parcelDialog"
      @close="parcelDialog = false"
      @submited="(parcelDialog = false), (parcelSubmited = true)"
    ></parcel-dialog>

    <channel-dialog
      :isEdit="isChannelEdit"
      :channel="channel"
      :modalActive="channelDialog"
      @close="channelDialog = false"
      @submited="(channelDialog = false), (channelSubmited = true)"
    ></channel-dialog>

    <box-dialog
      :isEdit="isBoxEdit"
      :box="box"
      :modalActive="boxDialog"
      @close="boxDialog = false"
      @submited="(boxDialog = false), (boxSubmited = true)"
    ></box-dialog>

    <action-dialog
      :isEdit="isActionEdit"
      :action="action"
      :modalActive="actionDialog"
      @close="actionDialog = false"
      @submited="(actionDialog = false), (actionSubmited = true)"
    ></action-dialog>

    <hotel-dialog
      :isEdit="isHotelEdit"
      :hotel="hotel"
      :modalActive="hotelDialog"
      @close="hotelDialog = false"
      @submited="(hotelDialog = false), (hotelSubmited = true)"
    ></hotel-dialog>

    <room-dialog
      :isEdit="isRoomEdit"
      :room="room"
      :modalActive="roomDialog"
      @close="roomDialog = false"
      @submited="(roomDialog = false), (roomSubmited = true)"
    ></room-dialog>

    <type-dialog
      :isEdit="isTypeEdit"
      :type="type"
      :modalActive="typeDialog"
      @close="typeDialog = false"
      @submited="(typeDialog = false), (typeSubmited = true)"
    ></type-dialog>
  </div>
</template>
<script>
import { TabPane, Tabs, Collapse, CollapseItem } from "src/components";
import { TimeSelect, Table, TableColumn, Select, Option } from "element-ui";
import { BaseCheckbox, BaseProgress, RouteBreadCrumb } from "src/components";
import axios from "@/util/axiosInstance";
import swal from "sweetalert2";
import { BaseNav, Modal } from "@/components";

import CardTable from "./tables/CardTable.vue";
import CardDialog from "./dialogs/CardDialog.vue";

import LockTable from "./tables/LockTable.vue";
import LockDialog from "./dialogs/LockDialog.vue";

import ServiceTable from "./tables/ServiceTable.vue";
import ServiceDialog from "./dialogs/ServiceDialog.vue";

import ParcelTable from "./tables/ParcelTable.vue";
import ParcelDialog from "./dialogs/ParcelDialog.vue";

import ChannelTable from "./tables/ChannelTable.vue";
import ChannelDialog from "./dialogs/ChannelDialog.vue";

import BoxTable from "./tables/BoxTable.vue";
import BoxDialog from "./dialogs/BoxDialog.vue";

import ActionTable from "./tables/ActionTable.vue";
import ActionDialog from "./dialogs/ActionDialog.vue";

import HotelTable from "./tables/HotelTable.vue";
import HotelDialog from "./dialogs/HotelDialog.vue";

import RoomTable from "./tables/RoomTable.vue";
import RoomDialog from "./dialogs/RoomDialog.vue";

import TypeTable from "./tables/TypeTable.vue";
import TypeDialog from "./dialogs/TypeDialog.vue";

export default {
  components: {
    [Table.name]: Table,
    [TimeSelect.name]: TimeSelect,
    [TableColumn.name]: TableColumn,
    [Option.name]: Option,
    [Select.name]: Select,
    TabPane,
    Tabs,
    Collapse,
    CollapseItem,
    Modal,
    CardTable,
    CardDialog,
    LockTable,
    LockDialog,
    ServiceTable,
    ServiceDialog,
    ParcelTable,
    ParcelDialog,
    ChannelTable,
    ChannelDialog,
    BoxTable,
    BoxDialog,
    ActionTable,
    ActionDialog,
    HotelTable,
    HotelDialog,
    RoomTable,
    RoomDialog,
    TypeTable,
    TypeDialog,
  },
  data() {
    return {
      isCardEdit: false,
      card: {
        name: "",
        uid: "",
      },
      cardDialog: false,
      cardSubmited: false,

      isLockEdit: false,
      lock: {
        name: "",
        description: "",
        serial: "",
      },
      lockDialog: false,
      lockSubmited: false,

      isServiceEdit: false,
      service: {
        name: "",
        description: "",
        price: 0,
        min_stay: 0,
        discount: 0,
        lock_id: 0,
      },
      serviceDialog: false,
      serviceSubmited: false,

      isParcelEdit: false,
      parcel: {
        name: "",
        ip: "",
        parcel_id: "",
      },
      parcelDialog: false,
      parcelSubmited: false,

      isChannelEdit: false,
      channel: {
        name: "",
        comission: 0,
      },
      channelDialog: false,
      channelSubmited: false,

      isBoxEdit: false,
      box: {
        name: "",
        number: 0,
        parcel_id: 0,
      },
      boxDialog: false,
      boxSubmited: false,

      isActionEdit: false,
      action: {
        info: {
          name: "",
          unit: "",
        },
      },
      actionDialog: false,
      actionSubmited: false,

      isHotelEdit: false,
      hotel: {
        name: "",
        location: "",
        action_id: 0,
        tax_adults: 0,
        tax_children_one: 0,
        tax_children_two: 0,
      },
      hotelDialog: false,
      hotelSubmited: false,

      isRoomEdit: false,
      room: {
        name: "",
        description: "",
        beds24_room_id: null,
        oteSync_type_id: null,
        box_id: 0,
        lock_id: 0,
      },
      roomDialog: false,
      roomSubmited: false,

      isTypeEdit: false,
      type: {
        id: 0,
        name: "",
        description: "",
        capacity: 0,
        price_per_night: 0,
        room_size: 0,
        bads_number: 0,
        bed_type: "",
        files: [],
      },
      typeDialog: false,
      typeSubmited: false,

      url: "https://admin.hotelsync.org/pms/api/v1",
    };
  },
  methods: {
    // card methods
    handleCardDialog() {
      this.cardDialog = false;
    },
    handleCardAdd() {
      this.cardDialog = true;
      this.isCardEdit = false;
      this.card = {
        name: "",
        uid: "",
      };
    },
    handleCardEdit(card) {
      this.isCardEdit = true;
      this.card = card;
      this.cardDialog = true;
    },

    // lock methods
    handleLockDialog() {
      this.lockDialog = false;
    },
    handleLockAdd() {
      this.lockDialog = true;
      this.isLockEdit = false;
      this.lock = {
        name: "",
        description: "",
        serial: "",
      };
    },
    handleLockEdit(lock) {
      this.isLockEdit = true;
      this.lock = lock;
      this.lockDialog = true;
    },

    // service methods
    handleServiceDialog() {
      this.serviceDialog = false;
    },
    handleServiceAdd() {
      this.serviceDialog = true;
      this.isServiceEdit = false;
      this.service = {
        name: "",
        description: "",
        price: 0,
        min_stay: 0,
        discount: 0,
        lock_id: 0,
      };
    },
    handleServiceEdit(service) {
      this.isServiceEdit = true;
      this.service = service;
      this.serviceDialog = true;
    },

    // parcel methods
    handleParcelDialog() {
      this.parcelDialog = false;
    },
    handleParcelAdd() {
      this.parcelDialog = true;
      this.isParcelEdit = false;
      this.parcel = {
        name: "",
        ip: "",
        parcel_id: "",
      };
    },
    handleParcelEdit(parcel) {
      this.isParcelEdit = true;
      this.parcel = parcel;
      this.parcelDialog = true;
    },

    // channel methods
    handleChannelDialog() {
      this.channelDialog = false;
    },
    handleChannelAdd() {
      this.channelDialog = true;
      this.isChannelEdit = false;
      this.channel = {
        name: "",
        comission: 0,
      };
    },
    handleChannelEdit(channel) {
      this.isChannelEdit = true;
      this.channel = channel;
      this.channelDialog = true;
    },

    // box methods
    handleBoxDialog() {
      this.boxDialog = false;
    },
    handleBoxAdd() {
      this.boxDialog = true;
      this.isBoxEdit = false;
      this.box = {
        name: "",
        number: 0,
      };
    },
    handleBoxEdit(box) {
      this.isBoxEdit = true;
      this.box = box;
      this.boxDialog = true;
    },

    // action methods
    handleActionDialog() {
      this.actionDialog = false;
    },
    handleActionAdd() {
      this.actionDialog = true;
      this.isActionEdit = false;
      this.action = {
        info: {
          name: "",
          unit: "",
        },
      };
    },
    handleActionEdit(action) {
      this.isActionEdit = true;
      this.action = action;
      this.actionDialog = true;
    },

    // hotel methods
    handleHotelDialog() {
      this.hotelDialog = false;
    },
    handleHotelAdd() {
      this.hotelDialog = true;
      this.isHotelEdit = false;
      this.hotel = {
        name: "",
        location: "",
        action_id: 0,
        tax_adults: 0,
        tax_children_one: 0,
        tax_children_two: 0,
      };
    },
    handleHotelEdit(hotel) {
      this.isHotelEdit = true;
      this.hotel = hotel;
      this.hotelDialog = true;
    },

    // room methods
    handleRoomDialog() {
      this.roomDialog = false;
    },
    handleRoomAdd() {
      this.roomDialog = true;
      this.isRoomEdit = false;
      this.room = {
        name: "",
        description: "",
        beds24_room_id: null,
        oteSync_type_id: null,
        box_id: 0,
        lock_id: 0,
      };
    },
    handleRoomEdit(room) {
      this.isRoomEdit = true;
      this.room = room;
      this.roomDialog = true;
    },

    // type methods
    handleTypeDialog() {
      this.typeDialog = false;
    },
    handleTypeAdd() {
      this.typeDialog = true;
      this.isTypeEdit = false;
      this.type = {
        id: 0,
        name: "",
        description: "",
        capacity: 0,
        price_per_night: 0,
        room_size: 0,
        bads_number: 0,
        bed_type: "",
        files: [],
      };
    },
    handleTypeEdit(type) {
      this.isTypeEdit = true;
      this.type = type;
      this.typeDialog = true;
    },
  },
};
</script>
<style lang="scss">
/* Pozadina modala */
.custom-modal {
  background-color: rgba(0, 0, 0, 0.8);
}

.custom-modal-content {
  background-color: #3b3b5c;
  /* Tamna pozadina */
  color: #ffffff;
  /* Svetli tekst */
  border-radius: 8px;
  max-width: 800px;
  width: 800px;
}

/* Stil za telo modala */
.custom-modal-body {
  padding: 20px;
}

/* Stil za tabelu unutar modala */
.custom-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #2c2c3e;
  /* Tamna pozadina tabele */
  color: rgba(255, 255, 255, 0.87);
  /* Svetlo sivi tekst */
}

.custom-table th {
  background-color: #3c3c4f;
  /* Tamna pozadina za zaglavlje */
  color: #ffffff;
  /* Beli tekst */
  padding: 10px;
  text-align: left;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.custom-table td {
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.87);
  /* Svetlo sivi tekst */
}

.custom-table tr:hover {
  background-color: rgba(255, 255, 255, 0.1);
  /* Istaknuta boja za hover */
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Visina loadera */
  background-color: rgba(255, 255, 255, 0.7);
  /* Providna pozadina */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  /* Iznad ostalog sadržaja */
}

.loader {
  border: 8px solid #f3f3f3;
  /* Svetla boja */
  border-top: 8px solid #3498db;
  /* Boja animacije */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>

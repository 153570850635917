<template>
  <modal
    :show.sync="localModalActive"
    class="modal"
    id="modal"
    modalClasses="custom-modal"
    modalContentClasses="custom-modal-content"
    bodyClasses="custom-modal-body"
    :centered="false"
    :show-close="true"
    @close="handleClose"
  >
    <card class="stacked-form" title="">
      <form @submit.prevent="submit">
        <div>
          <div class="row">
            <div class="col-12">
              <base-input v-model="service.name" label="Naziv" type="text">
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <base-input
                v-model="service.description"
                label="Opis"
                type="text"
              >
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <base-input v-model="service.price" label="Cena" type="number">
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <base-input
                v-model="service.min_stay"
                label="Minimalni broj dana"
                type="number"
              >
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <base-input
                v-model="service.discount"
                label="Popust (procenat)"
                type="number"
              >
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <base-input label="Brave">
                <el-select
                  @focus="getLocks()"
                  v-model="lock"
                  class="select-primary"
                  clearable
                  value-key="id"
                  placeholder="Izaberi bravu"
                >
                  <el-option
                    v-for="p in locks"
                    class="select-primary"
                    :label="p.name"
                    :value="p"
                    :key="p.id"
                  ></el-option>
                </el-select>
              </base-input>
              <!-- <base-button
                    class="mt-3"
                    native-type="submit"
                    type="primary"
                    @click="addService()"
                    >Dodaj servis</base-button
                  > -->
            </div>
          </div>
        </div>

        <base-button native-type="submit" type="primary" class="mb-3" size="lg"
          >Sačuvaj</base-button
        >
      </form>
    </card>
  </modal>
</template>

<script>
import { BaseNav, Modal } from "@/components";
import axios from "@/util/axiosInstance";
import swal from "sweetalert2";
import {
  Table,
  TableColumn,
  TimeSelect,
  DatePicker,
  Select,
  Option,
} from "element-ui";

export default {
  name: "service-dialog",
  props: {
    isEdit: false,
    modalActive: {
      service: Boolean,
      default: false,
      sync: true,
    },
    service: {
      type: Object,
      default: () => ({
        name: "",
        description: "",
        price: 0,
        min_stay: 0,
        discount: 0,
        lock_id: 0,
      }),
    },
  },
  components: {
    BaseNav,
    Modal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
  },

  watch: {
    service: function (newValue, oldValue) {
      if (newValue?.lock_id && this.isEdit) {
        this.lock = this.locks.find((p) => p.id === newValue.lock_id);
      } else {
        this.lock = {};
      }
    },
    modalActive: function (newValue, oldValue) {
      this.localModalActive = newValue;
    },
  },

  mounted() {
    this.getLocks();
  },

  data() {
    return {
      localModalActive: this.modalActive,
      lock: {},
      locks: [],
    };
  },
  methods: {
    async getLocks() {
      let result = await axios.get("/locks?limit=900");

      if (result?.data?.results) {
        this.locks = result.data.results;
      }
    },
    handleClose() {
      this.$emit("close");
    },
    async submit() {
      this.service.lock_id = this.lock.id;

      if (this.isEdit) {
        let result = await axios.patch(
          "/services/" + this.service.id,
          this.service
        );

        if (!result?.data?.errors) {
          swal
            .fire({
              icon: "success",
              title: "Uspešno ste ažurirali zapis",
            })
            .then((result) => {
              if (result.value) {
                this.$emit("submited");
              }
            });
        } else {
          await swal.fire({
            icon: "error",
            title: "Error",
            text: result?.data?.errors[0].message,
          });
        }
      } else {
        let result = await axios.post("/services", this.service);
        if (!result?.data?.errors) {
          swal
            .fire({
              icon: "success",
              title: "Uspešno ste sačuvali zapis",
            })
            .then((result) => {
              if (result.value) {
                this.$emit("submited");
              }
            });
        } else {
          await swal.fire({
            icon: "error",
            title: "Error",
            text: result?.data?.errors[0].message,
          });
        }
      }
    },
  },
};
</script>

<template>
    <div>
        <div v-if="loading" class="loader-container">
            <div class="loader"></div> <!-- Stilizovani loader -->
        </div>
        <div class="row">
            <div class="col-md-24">
                <card>
                    <template slot="header">
                        <h5 class="card-category"></h5>
                        <h3 class="card-title">Grupe besplatnih karata</h3>
                    </template>


                    <div class="row">
                        <div class="col-md-3">
                            <card>
                                <h4 slot="header" class="card-title"> </h4>
                                <base-button class="mt-3" native-type="submit" type="primary"
                                    @click="category = { }; editCategory = true">Dodaj novu kategoriju</base-button>
                            </card>
                        </div>
                    </div>
                    <el-table :data="whitelistCategories" v-loading="ticketsLoading">
                        <el-table-column min-width="80" prop="name" label="Ime">
                            <template slot-scope="scope">
                                <span>{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="100" header-align="right" align="right" label="Actions">
                            <div slot-scope="scope" class="text-right table-actions">

                                <base-button :type="'success'" class="btn-link"
                                    @click="category = scope.row; editCategory = true">
                                    Izmeni
                                </base-button>

                                <base-button :type="'danger'" class="btn-link"
                                    @click="category = scope.row; deleteCategoryPrompt()">
                                    Obriši
                                </base-button>

                            </div>
                        </el-table-column>
                    </el-table>
                </card>
            </div>


        </div>
        <modal :show.sync="editCategory" :show-close="true" :scrollToBottom="true" :appendToBody="true"
            modalClasses="custom-modal" modalContentClasses="custom-modal-content" bodyClasses="custom-modal-body">
            <base-button :type="'danger'" icon size="sm" class="btn-link"
                @click="editWhitelist = false; whitelist = {}">
                <i class="tim-icons icon-simple-remove"></i>
            </base-button>


            <card class="stacked-form" title="Kategorija">
                <form @submit.prevent>
                            <div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <base-input label="Ime" type="text" v-model="category.name"
                                            placeholder="Ime">
                                        </base-input>
                                    </div>
                                </div>

                            </div>
                        </form>
                       
                <base-button class="mt-3" native-type="submit" style="margin-left: 20px;" type="primary"
                    @click="saveCategory()">Sačuvaj</base-button>
                <base-button class="mt-3" native-type="submit" style="margin-left: 20px;" type="danger"
                    @click="editCategory = false; category = {}">Odustani</base-button>
            </card>
                    
        </modal>

        
    </div>
</template>
<script>
import { TabPane, Tabs, Collapse, CollapseItem } from 'src/components';
import { Table, TableColumn, TimeSelect, DatePicker, Select, Option } from 'element-ui';
import { BaseCheckbox, BaseProgress, RouteBreadCrumb } from 'src/components';
import axios from 'axios'
import swal from 'sweetalert2';
import { BaseNav, Modal } from '@/components';
import htmlDocx from 'html-docx-js';
import { saveAs } from 'file-saver';


export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [DatePicker.name]: DatePicker,
        [TimeSelect.name]: TimeSelect,
        [Option.name]: Option,
        [Select.name]: Select,
        TabPane,
        Tabs,
        Collapse,
        CollapseItem,
        Modal
    },
    mounted() {
        this.getWhitelistCategories()
        // this.getClients()
    },
    data() {
        const now = new Date();

        // Postavljanje startStamp na 00:00:00 danas
        const startStamp = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0).getTime();

        // Postavljanje endStamp na 23:59:59 danas
        const endStamp = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59).getTime();

        return {
            url: 'https://hercegnovi.scancar.org:8443/kuca',
            ticketsLoading: false,
            loading: false,
            whitelistTypes: [],
            whitelistType: {},
            showWhitelistType: false,
            clients: [],
            showParkings: false,
            whitelistCategories: [],
            category: {},
            editCategory: false
        };
    },
    methods: {
        getWhitelistCategories() {
            this.ticketsLoading = true
            axios.get(this.url + '/whitelist/getWhitelistCategoriesFree',).then(result => {
                this.ticketsLoading = false

                if (result.data.result === 'ok') {
                    this.whitelistCategories = result.data.whitelistCategories
                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        saveCategory() {
            this.loading = true
            axios.post(this.url + '/whitelist/saveWhitelistCategory', this.category).then(result => {
                this.loading = false

                if (result.data.result === 'ok') {
                    this.$notify({
                        message:
                            'Sacuvano',
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'success'
                    });
                    this.editCategory = false
                    this.category = {}
                    this.getWhitelistCategories()
                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        deleteCategory() {
            this.loading = true
            axios.post(this.url + '/whitelist/deleteWhitelistCategory', this.category).then(result => {
                this.loading = false

                if (result.data.result === 'ok') {
                    this.$notify({
                        message:
                            'Obrisano',
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'success'
                    });
                    this.getWhitelistCategories()
                } else {
                    this.$notify({
                        message:
                            result.data.reason,
                        timeout: 3000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: 'danger'
                    });
                }
            })
        },
        deleteCategoryPrompt() {
            const swalWithBootstrapButtons4 = swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success btn-fill",
                    cancelButton: "btn btn-danger btn-fill",
                },
                buttonsStyling: false,
            });
            swalWithBootstrapButtons4
                .fire({
                    title: "Da li ste sigurni da želite da obrišete kategoriju \"" + this.category.name + "\"?",
                    text: ``,
                    confirmButtonText: "Da, obriši!",
                    cancelButtonText: "NE",
                    showCancelButton: true,
                })
                .then((result) => {
                    if (result.value) {
                        this.deleteCategory()
                        // const swalWithBootstrapButtons5 = swal.mixin({
                        //     customClass: {
                        //     confirmButton: "btn btn-success btn-fill",
                        //     },
                        //     buttonsStyling: false,
                        // });
                        // swalWithBootstrapButtons5.fire({
                        //     title: "Deleted!",
                        //     text: "Your file has been deleted.",
                        // });
                    }
                });
        },
        saveTicketPrompt() {
            const swalWithBootstrapButtons4 = swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success btn-fill",
                    cancelButton: "btn btn-danger btn-fill",
                },
                buttonsStyling: false,
            });

            swalWithBootstrapButtons4
                .fire({
                    title: "Molimo vas unesite komentar za ovu promenu.",
                    html: `<textarea id="comment" class="swal2-textarea" placeholder="Komentar" style="width: 100%; height: 100px;"></textarea>`,
                    confirmButtonText: "Sačuvaj",
                    cancelButtonText: "Odustani",
                    showCancelButton: true,
                    preConfirm: () => {
                        const comment = document.getElementById("comment").value;
                        // if (!comment.trim()) {
                        //     Swal.showValidationMessage("Molimo unesite komentar pre nego što nastavite!");
                        // }
                        return comment;
                    },
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        const comment = result.value; // Dohvaćen komentar
                        console.log("Komentar:", comment); // Možete proslediti komentar funkciji
                        // this.deleteParkingSpace(comment);
                        this.ticket.comment = comment;
                        this.saveTicket()
                    }
                });
        }

    }
};
</script>
<style lang="scss">
/* Pozadina modala */
.custom-modal {
    background-color: rgba(0, 0, 0, 0.8);
}

.custom-modal-content {
    background-color: #3b3b5c;
    /* Tamna pozadina */
    color: #ffffff;
    /* Svetli tekst */
    border-radius: 8px;
    max-width: 800px;
    width: 800px;
}

/* Stil za telo modala */
.custom-modal-body {
    padding: 20px;
}

/* Stil za tabelu unutar modala */
.custom-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #2c2c3e;
    /* Tamna pozadina tabele */
    color: rgba(255, 255, 255, 0.87);
    /* Svetlo sivi tekst */
}

.custom-table th {
    background-color: #3c3c4f;
    /* Tamna pozadina za zaglavlje */
    color: #ffffff;
    /* Beli tekst */
    padding: 10px;
    text-align: left;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.custom-table td {
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.87);
    /* Svetlo sivi tekst */
}

.custom-table tr:hover {
    background-color: rgba(255, 255, 255, 0.1);
    /* Istaknuta boja za hover */
}


.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Visina loadera */
    background-color: rgba(255, 255, 255, 0.7);
    /* Providna pozadina */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    /* Iznad ostalog sadržaja */
}

.loader {
    border: 8px solid #f3f3f3;
    /* Svetla boja */
    border-top: 8px solid #3498db;
    /* Boja animacije */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
<template>
    <div>
        <div v-if="loading" class="loader-container">
            <div class="loader"></div> <!-- Stilizovani loader -->
        </div>
        <div class="row">
            <div class="col-md-24">
                <card>
                    <template slot="header">
                        <h5 class="card-category"></h5>
                        <h3 class="card-title">Rezervacije ({{ total }})</h3>
                    </template>
                    <div class="row">
                        <div class="col-md-3">
                            <card>
                                <h4 slot="header" class="card-title"> </h4>
                                <base-button class="mt-3" native-type="submit" type="primary"
                                    @click="reservation = { num_adults: 1, num_children_one: 0, num_children_two: 0 , price_info: []}; editReservation = true">Nova
                                    rezervacija</base-button>
                            </card>
                        </div>
                    </div>

                    <el-table :data="reservations" v-loading="reservationsLoading">


                        <el-table-column min-width="200" prop="room_id" label="Soba">
                            <template slot-scope="scope">
                                <span>{{ scope.row.room.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="100" prop="date_from" label="Od">
                            <template slot-scope="scope">
                                <span>{{ scope.row.date_from }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="100" prop="date_to" label="Do">
                            <template slot-scope="scope">
                                <span>{{ scope.row.date_to }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="100" prop="first_name" label="Ime">
                            <template slot-scope="scope">
                                <span>{{ scope.row.first_name }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="100" prop="last_name" label="Prezime">
                            <template slot-scope="scope">
                                <span>{{ scope.row.last_name }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="100" prop="price" label="Cena">
                            <template slot-scope="scope">
                                <span>{{ scope.row.price }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column min-width="100" prop="res_code" label="Kod">
                            <template slot-scope="scope">
                                <span>{{ scope.row.res_code }}</span>
                            </template>
                        </el-table-column>



                        <el-table-column min-width="100" header-align="right" align="right" label="Actions">
                            <div slot-scope="scope" class="text-right table-actions">

                                <base-button :type="'success'" class="btn-link"
                                    @click="reservation = scope.row; editReservation = true">
                                    Detalji
                                </base-button>

                            </div>
                        </el-table-column>
                    </el-table>
                </card>
            </div>


        </div>
        <modal :show.sync="editReservation" :show-close="true" :scrollToBottom="true" :appendToBody="true"
            modalClasses="custom-modal" modalContentClasses="custom-modal-content" bodyClasses="custom-modal-body">
            <base-button :type="'danger'" icon size="sm" class="btn-link"
                @click="editReservation = false; reservation = {}">
                <i class="tim-icons icon-simple-remove"></i>
            </base-button>


            <card class="stacked-form" title="">
                <form @submit.prevent>
                    <div>
                        <div class="row">
                            <div class="col-md-6">
                                <base-input label="Kod rezervacije" type="text" v-model="reservation.res_code">
                                </base-input>
                            </div>
                            <div class="col-md-6">
                                <base-input label="Soba">
                                    <el-select v-model="reservation.room_id" class="select-primary" clearable>
                                        <el-option v-for="p in rooms" class="select-primary" :label="p.name"
                                            :value="p.id" :key="p.id"></el-option>
                                    </el-select>
                                </base-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <base-input label="Od">
                                    <el-date-picker type="date" placeholder="Početak" value-format="yyyy-MM-dd"
                                        v-model="reservation.date_from">
                                    </el-date-picker>
                                </base-input>
                            </div>
                            <div class="col-md-6">
                                <base-input label="Do">
                                    <el-date-picker type="date" placeholder="Kraj" value-format="yyyy-MM-dd"
                                        v-model="reservation.date_to">
                                    </el-date-picker>
                                </base-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <base-input label="Ime" type="text" v-model="reservation.first_name">
                                </base-input>
                            </div>
                            <div class="col-md-6">
                                <base-input label="Prezime" type="text" v-model="reservation.last_name">
                                </base-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <base-input label="E-Mail" type="text" v-model="reservation.email">
                                </base-input>
                            </div>
                            <div class="col-md-6">
                                <base-input label="Telefon" type="text" v-model="reservation.phone">
                                </base-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <base-input label="Odrasli">
                                    <el-input-number v-model="reservation.num_adults" />
                                </base-input>

                            </div>
                            <div class="col-md-4">
                                <base-input label="Deca 0-7">
                                    <el-input-number v-model="reservation.num_children_one" />
                                </base-input>
                            </div>
                            <div class="col-md-4">
                                <base-input label="Deca 7-15">
                                    <el-input-number v-model="reservation.num_children_two" />
                                </base-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <span>{{ reservation.real_price }}</span>
                            </div>
                            <div class="col-md-6">
                                <base-input label="Cena" type="text" v-model="reservation.price">
                                </base-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <base-input label="Status placanja">
                                    <el-select v-model="reservation.status_type" class="select-primary" clearable>
                                        <el-option v-for="p in paymentStatuses" class="select-primary" :label="p.name"
                                            :value="p.id" :key="p.id"></el-option>
                                    </el-select>
                                </base-input>
                            </div>
                            <div class="col-md-6">
                                <base-input label="Kanal rezervacije">
                                    <el-select v-model="reservation.channel_id" class="select-primary" clearable>
                                        <el-option v-for="p in channels" class="select-primary" :label="p.name"
                                            :value="p.id" :key="p.id"></el-option>
                                    </el-select>
                                </base-input>
                            </div>
                        </div>

                        <!-- Servisi -->
                        <div class="row">
                            <div class="col-md-6">
                                <base-input label="Servis">
                                    <el-select v-model="service" class="select-primary" clearable value-key="id">
                                        <el-option v-for="p in services" class="select-primary" :label="p.name"
                                            :value="p" :key="p.id"></el-option>
                                    </el-select>
                                </base-input>
                                <base-button class="mt-3" native-type="submit" type="primary"
                                    @click="addService()">Dodaj
                                    servis</base-button>
                            </div>
                            <div class="col-md-6">
                                <el-table :data="reservation.price_info" >


                                    <el-table-column min-width="200" prop="name" label="Ime">
                                        <template slot-scope="scope">
                                            <span>{{ scope.row.name }} ({{ scope.row.price }})</span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>

                    </div>
                </form>

                <base-button class="mt-3" native-type="submit" style="margin-left: 20px;" type="primary"
                    @click="saveReservation()">Sačuvaj</base-button>
                <base-button class="mt-3" native-type="submit" style="margin-left: 20px;" type="danger"
                    @click="editReservation = false; reservation = {}">Odustani</base-button>
            </card>

        </modal>
    </div>
</template>
<script>
import { TabPane, Tabs, Collapse, CollapseItem } from 'src/components';
import { Table, TableColumn, TimeSelect, DatePicker, Select, Option } from 'element-ui';
import { BaseCheckbox, BaseProgress, RouteBreadCrumb } from 'src/components';
import axios from '@/util/axiosInstance'
import swal from 'sweetalert2';
import { BaseNav, Modal } from '@/components';
import htmlDocx from 'html-docx-js';
import { saveAs } from 'file-saver';

const paymentStatuses = [
    { id: 1, name: "Nije placeno" },
    { id: 2, name: "Delimicno placeno" },
    { id: 3, name: "Placeno u potpunosti" }
]
const channels = [
    { id: 1, name: "Trip.com" },
    { id: 2, name: "Ostrovak" },
    { id: 3, name: "Airbnb" },
    { id: 4, name: "Hotelbeds" },
    { id: 5, name: "Direct" },
    { id: 6, name: "Booking.com" },
    { id: 7, name: "Expedia.com" },
]

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [DatePicker.name]: DatePicker,
        [TimeSelect.name]: TimeSelect,
        [Option.name]: Option,
        [Select.name]: Select,
        TabPane,
        Tabs,
        Collapse,
        CollapseItem,
        Modal
    },
    mounted() {
        this.getRoomTypes()
        this.getRooms()
        this.getServices()
        // this.getReservations()
    },
    data() {
        const now = new Date();

        // Postavljanje startStamp na 00:00:00 danas
        const startStamp = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0).getTime();

        // Postavljanje endStamp na 23:59:59 danas
        const endStamp = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59).getTime();

        return {
            paymentStatuses,
            channels,
            reservationsLoading: false,
            loading: false,
            reservations: [],
            filter: {
                startStamp: startStamp,
                endStamp: endStamp,
                ticketNumber: null,
                plates: null,
                countryMark: null,
                employeeId: null,
                paymentStatusId: null,
                clientId: null
            },
            total: 0,
            rooms: [],
            roomMap: [],
            roomTypes: [],
            services: [],
            parkomats: [],
            reservation: {},
            editReservation: false,
            service: {}
        };
    },
    methods: {
        addService() {
            if (!this.reservation.price_info) {
                this.reservation.price_info = []
            }
            this.reservation.price_info.push(this.service)

            console.log(this.reservation);
        },
        getRooms() {
            axios.get('/rooms').then(result => {
                if (result.data.results) {
                    this.rooms = result.data.results
                    this.roomMap = this.rooms.reduce((map, room) => {
                        map[room.id] = room;
                        return map;
                    }, {});
                    this.getReservations()
                } else {

                }
            })
        },
        saveReservation() {
            this.loading = true
            this.reservation.type = 1
            this.reservation.price_info = []
            this.reservation.real_price = this.reservation.price
            axios.post('/reservations', this.reservation).then(result => {
                this.loading = false
                if (result.data.id) {
                    this.editReservation = false
                    this.reservation = {}
                    this.getReservations()
                } else {

                }
            })
                .catch(error => {
                    this.loading = false; // Zaustavi indikator učitavanja
                    console.error('Došlo je do greške:', error); // Logovanje greške u konzolu

                    // Obrada greške na osnovu statusa
                    if (error.response) {
                        // Server je odgovorio sa status kodom van opsega 2xx
                        console.log('HTTP Status kod:', error.response.status);
                        console.log('Podaci o grešci:', error.response.data);
                    } else if (error.request) {
                        // Zahtev je poslat, ali nema odgovora
                        console.log('Nema odgovora od servera:', error.request);
                    } else {
                        // Nešto drugo je pošlo naopako prilikom podešavanja zahteva
                        console.log('Greška prilikom podešavanja zahteva:', error.message);
                    }
                });
        },
        getRoomTypes() {
            axios.get('/types').then(result => {
                if (result.data.results) {
                    this.roomTypes = result.data.results
                } else {

                }
            })
        },
        getParkomats() {
            axios.get('/parcels').then(result => {
                if (result.data.results) {
                    this.parkomats = result.data.results
                } else {

                }
            })
        },
        getServices() {
            axios.get('/services').then(result => {
                if (result.data.results) {
                    this.services = result.data.results
                } else {

                }
            })
        },
        getReservations() {
            this.reservationsLoading = true
            axios.get('/reservations').then(result => {
                this.reservationsLoading = false

                if (result.data.results) {
                    this.reservations = result.data.results.map(reservation => ({
                        ...reservation,
                        room: this.roomMap[reservation.room_id] || null, // Dodaj sobu ili null ako ne postoji
                    }));
                    this.total = result.data.total;
                } else {

                }
            })
        }

    }
};
</script>
<style lang="scss">
/* Pozadina modala */
.custom-modal {
    background-color: rgba(0, 0, 0, 0.8);
}

.custom-modal-content {
    background-color: #3b3b5c;
    /* Tamna pozadina */
    color: #ffffff;
    /* Svetli tekst */
    border-radius: 8px;
    max-width: 800px;
    width: 800px;
}

/* Stil za telo modala */
.custom-modal-body {
    padding: 20px;
}

/* Stil za tabelu unutar modala */
.custom-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #2c2c3e;
    /* Tamna pozadina tabele */
    color: rgba(255, 255, 255, 0.87);
    /* Svetlo sivi tekst */
}

.custom-table th {
    background-color: #3c3c4f;
    /* Tamna pozadina za zaglavlje */
    color: #ffffff;
    /* Beli tekst */
    padding: 10px;
    text-align: left;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.custom-table td {
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.87);
    /* Svetlo sivi tekst */
}

.custom-table tr:hover {
    background-color: rgba(255, 255, 255, 0.1);
    /* Istaknuta boja za hover */
}


.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Visina loadera */
    background-color: rgba(255, 255, 255, 0.7);
    /* Providna pozadina */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    /* Iznad ostalog sadržaja */
}

.loader {
    border: 8px solid #f3f3f3;
    /* Svetla boja */
    border-top: 8px solid #3498db;
    /* Boja animacije */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
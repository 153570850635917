<template>
  <modal
    :show.sync="localModalActive"
    class="modal"
    id="modal"
    modalClasses="custom-modal"
    modalContentClasses="custom-modal-content"
    bodyClasses="custom-modal-body"
    :centered="false"
    :show-close="true"
    @close="handleClose"
  >
    <card class="stacked-form" title="">
      <form @submit.prevent="submit">
        <div>
          <div class="row">
            <div class="col-12">
              <base-input v-model="type.name" label="Naziv" type="text">
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <base-input v-model="type.description" label="Opis" type="text">
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <base-input
                v-model="type.capacity"
                label="Kapacitet"
                type="number"
              >
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <base-input
                v-model="type.price_per_night"
                label="Cena (noćenje)"
                type="number"
              >
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <base-input
                v-model="type.room_size"
                label="Veličina sobe"
                type="number"
              >
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <base-input
                v-model="type.bads_number"
                label="Broj kreveta"
                type="number"
              >
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <base-input
                v-model="type.bed_type"
                label="Tip kreveta"
                type="text"
              >
              </base-input>
            </div>
          </div>

          <!-- image gallery -->
          <div class="row">
            <div
              class="col-12"
              style="
                margin: 10px 0;
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                flex-direction: row;
                justify-content: center;
              "
            >
              <div v-for="image in filesEdit">
                <a
                  :href="`https://admin.hotelsync.org/pms/files/types/${type.id}/${image}`"
                  target="_blank"
                >
                  <img
                    :src="`https://admin.hotelsync.org/pms/files/types/${type.id}/${image}`"
                    alt=""
                    width="100px"
                    height="100px"
                  />
                </a>
              </div>
            </div>
          </div>

          <!-- image upload -->
          <div class="row">
            <div class="col-12 mt-4 mb-4">
              <el-upload
                class="upload-demo"
                action=""
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :on-change="handleChange"
                :file-list="files"
                multiple
                list-type="picture"
                :limit="20"
                :auto-upload="false"
              >
                <el-button size="small" type="primary"
                  >Click to upload</el-button
                >
                <div slot="tip" class="el-upload__tip">
                  jpg/png files with a size less than 500kb
                </div>
              </el-upload>
            </div>
          </div>

          <!-- add rooms -->
          <div class="row">
            <div class="col-md-6">
              <base-input label="Soba">
                <el-select
                  @focus="getRooms()"
                  v-model="room"
                  class="select-primary"
                  clearable
                  value-key="id"
                >
                  <el-option
                    v-for="p in rooms"
                    class="select-primary"
                    :label="p.name"
                    :value="p"
                    :key="p.id"
                  ></el-option>
                </el-select>
              </base-input>
              <base-button class="mt-3" type="primary" @click="addRoom()"
                >Dodaj sobu</base-button
              >
            </div>
            <div class="col-md-6">
              <h4>Dodate sobe:</h4>
              <el-table :data="selectedRooms">
                <el-table-column min-width="200" prop="name" label="Ime sobe">
                  <template slot-scope="scope">
                    <span>{{ scope.row.name }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>

          <!-- add services -->
          <div class="row mb-4">
            <div class="col-md-6">
              <base-input label="Servis">
                <el-select
                  @focus="getServices()"
                  v-model="service"
                  class="select-primary"
                  clearable
                  value-key="id"
                >
                  <el-option
                    v-for="p in services"
                    class="select-primary"
                    :label="p.name"
                    :value="p"
                    :key="p.id"
                  ></el-option>
                </el-select>
              </base-input>
              <base-button class="mt-3" type="primary" @click="addService()"
                >Dodaj servis</base-button
              >
            </div>
            <div class="col-md-6">
              <h4>Dodati servisi:</h4>
              <el-table :data="selectedServices">
                <el-table-column
                  min-width="200"
                  prop="name"
                  label="Ime servisa"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.name }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>

        <base-button native-type="submit" type="primary" class="mb-3" size="lg"
          >Sačuvaj</base-button
        >
      </form>
    </card>
  </modal>
</template>

<script>
import { BaseNav, Modal } from "@/components";
import axios from "@/util/axiosInstance";
import swal from "sweetalert2";
import {
  Table,
  TableColumn,
  TimeSelect,
  DatePicker,
  Select,
  Option,
  Upload,
  Button,
} from "element-ui";

export default {
  name: "type-dialog",
  components: {
    BaseNav,
    Modal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
    [Upload.name]: Upload,
    [Button.name]: Button,
  },
  props: {
    isEdit: false,
    modalActive: {
      type: Boolean,
      default: false,
      sync: true,
    },
    type: {
      type: Object,
      default: () => ({
        id: 0,
        name: "",
        description: "",
        capacity: 0,
        price_per_night: 0,
        room_size: 0,
        bads_number: 0,
        bed_type: "",
        files: [],
      }),
    },
  },

  watch: {
    type: function (newValue, oldValue) {
      if (newValue.files) {
        this.filesEdit = JSON.parse(newValue.files);

        console.log(this.filesEdit);
      } else {
        this.filesEdit = [];
      }
    },
    modalActive: function (newValue, oldValue) {
      this.localModalActive = newValue;
    },
  },

  data() {
    return {
      localModalActive: this.modalActive,
      files: [],
      room: {},
      rooms: [],
      selectedRooms: [],

      service: {},
      services: [],
      selectedServices: [],

      addedFiles: [],
      filesEdit: [],
    };
  },
  mounted() {
    this.getRooms();
    this.getServices();
  },
  methods: {
    addRoom() {
      this.selectedRooms = this.selectedRooms.concat(this.room);
      // this.rooms = this.rooms.filter((r) => r.id !== this.room.id);

      // this.selectedRooms = this.selectedRooms.map((room) => ({
      //   room_id: room.id,
      // }));
    },

    addService() {
      this.selectedServices = this.selectedServices.concat(this.service);
    },

    async getRooms() {
      let result = await axios.get("/rooms?limit=900");

      if (result?.data?.results) {
        this.rooms = result.data.results;
      }
    },
    async getServices() {
      let result = await axios.get("/services?limit=900");

      if (result?.data?.results) {
        this.services = result.data.results;
      }
    },
    handleChange(file, fileList) {
      let fFiles = [];
      for (const element of fileList) {
        fFiles.push(element.raw);
      }

      this.addedFiles = fFiles;
      this.files = fileList;

      // const newFiles = fileList.map((f) => ({
      //   name: f.name,
      //   url: f.url,
      // }));
      // this.files = newFiles;
    },
    handleRemove(file, files) {
      this.addedFiles = [];
      console.log(file, files);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleClose() {
      this.$emit("close");
    },
    async submit() {
      const formData = new FormData();

      formData.append("name", this.type.name);
      formData.append("description", this.type.description);
      formData.append("capacity", this.type.capacity);
      formData.append("price_per_night", this.type.price_per_night);
      formData.append("room_size", this.type.room_size);
      formData.append("bads_number", this.type.bads_number);
      formData.append("bed_type", this.type.bed_type);

      // console.log("files: ", this.files);
      let selectedRooms = this.selectedRooms.map((room) => ({
        room_id: room.id.toString(),
      }));
      formData.append("rooms", JSON.stringify(selectedRooms));

      let selectedServices = this.selectedServices
        .map((service) => service.id.toString())
        .join(",");

      formData.append("services", selectedServices);

      // console.log("selected rooms: ", JSON.stringify(selectedRooms));

      // console.log("files: ", this.files);
      // return;
      // this.files.forEach((file) => {
      //   formData.append("files", file.raw);
      // });
      formData.append("files", null);

      if (this.isEdit) {
        const token = localStorage.getItem("access_token");
        const tenant = localStorage.getItem("tenant");
        const result = await axios.patch(
          "/types/" + this.type.id,
          this.type,
          formData,
          {
            headers: {
              tenant: tenant,
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!result?.data?.errors) {
          if (this.files.length > 0) {
            const fileData = new FormData();

            for (let i = 0; i < this.addedFiles.length; i++) {
              fileData.append("files", this.addedFiles[i]);
            }
            // fileData.append("files", this.addedFiles);
            const addImage = await axios.patch(
              "/types/" + result.data.id + "/images/add",
              fileData,
              {
                headers: {
                  tenant: tenant,
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            if (!addImage?.data?.errors) {
              swal
                .fire({
                  icon: "success",
                  title: "Uspešno ste sačuvali zapis",
                })
                .then((result) => {
                  if (result.value) {
                    this.$emit("submited");
                  }
                });
            }
          } else {
            swal
              .fire({
                icon: "success",
                title: "Uspešno ste sačuvali zapis",
              })
              .then((result) => {
                if (result.value) {
                  this.$emit("submited");
                }
              });
          }
        } else {
          await swal.fire({
            icon: "error",
            title: "Error",
            text: result?.data?.errors[0].message,
          });
        }
      } else {
        // let result = await axios.post("/types", this.type);
        const token = localStorage.getItem("access_token");
        const tenant = localStorage.getItem("tenant");
        const result = await axios.post("/types", formData, {
          headers: {
            tenant: tenant,
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!result?.data?.errors) {
          if (this.files.length > 0) {
            const fileData = new FormData();
            for (let i = 0; i < this.addedFiles.length; i++) {
              fileData.append("files", this.addedFiles[i]);
            }
            // fileData.append("files", this.addedFiles);
            const addImage = await axios.patch(
              "/types/" + result.data.id + "/images/add",
              fileData,
              {
                headers: {
                  tenant: tenant,
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            if (!addImage?.data?.errors) {
              swal
                .fire({
                  icon: "success",
                  title: "Uspešno ste sačuvali zapis",
                })
                .then((result) => {
                  if (result.value) {
                    this.$emit("submited");
                  }
                });
            }
          } else {
            swal
              .fire({
                icon: "success",
                title: "Uspešno ste sačuvali zapis",
              })
              .then((result) => {
                if (result.value) {
                  this.$emit("submited");
                }
              });
          }
        } else {
          await swal.fire({
            icon: "error",
            title: "Error",
            text: result?.data?.errors[0].message,
          });
        }
      }
    },
  },
};
</script>
